<template>
  <section class="suppliers">
    <div class="suppliers-ui" :class="{ active: filterActive }">
      <footwear-filters v-if="category == 0" :activeFilters.sync="filterActive"></footwear-filters>
      <component-filters v-if="category == 1" :activeFilters.sync="filterActive"></component-filters>
      <button
        class="subscribe-button"
        v-if="$store.user.loggedIn"
        :class="{ 'is-subscribed': subscriptionStatus }"
        @click.prevent="toggleSubscription"
      >
        {{ subscriptionStatus == true ? 'Subscribed' : 'Subscribe' }}
      </button>
    </div>
    <div class="results">
      <div class="results__list" v-if="suppliers && suppliers.list && suppliers.list.length && category == 0">
        <div
          class="results__item"
          :class="{ 'results__item--member': fsMember(supplier) }"
          v-for="(supplier, i) in suppliers.list"
          :key="i"
        >
          <router-link :to="{ name: 'supplier', params: { name: supplier.slug } }" class="search-item">
            <div class="search-item__photo">
              <div
                class="search-item__photo-bg"
                v-if="supplier.coverPhoto"
                :style="{ backgroundImage: `url('${imagePath('images/' + supplier.coverPhoto, 960, 250)}')` }"
              ></div>
              <div class="search-item__logos">
                <span class="search-item__logo" v-if="fsMember(supplier)" v-html="svg.fsMember"></span>
                <span
                  class="search-item__logo search-item__logo--small"
                  v-if="supplier.satra"
                  v-html="svg.satraMember"
                ></span>
              </div>
            </div>
            <div class="search-item__info">
              <div class="search-item__info-header">
                <strong class="search-item__info-title">{{ supplier.title }}</strong>
                <span class="search-item__info-icon" v-if="supplier.men" v-html="svg.men"></span>
                <span class="search-item__info-icon" v-if="supplier.ladies" v-html="svg.ladies"></span>
              </div>
              <p class="search-item__info-address">{{ supplier.fullAddress }}</p>
              <p class="search-item__info-list" v-if="supplier.footwearTypes">
                {{ getTitles(supplier.footwearTypes) }}
              </p>
              <p class="search-item__info-list" v-if="supplier.processes">
                {{ getTitles(supplier.processes) }}
              </p>
            </div>
          </router-link>
        </div>
      </div>
      <div class="results__list" v-if="suppliers && suppliers.list && suppliers.list.length && category == 1">
        <div class="results__item" v-for="(supplier, i) in suppliers.list" :key="i">
          <router-link :to="{ name: 'supplier', params: { name: supplier.slug } }" class="search-item">
            <div class="search-item__photo">
              <div
                class="search-item__photo-bg"
                v-if="supplier.coverPhoto"
                :style="{ backgroundImage: `url('${imagePath('images/' + supplier.coverPhoto, 960, 250)}')` }"
              ></div>
              <div class="search-item__logos">
                <span class="search-item__logo" v-if="fsMember(supplier)" v-html="svg.fsMember"></span>
                <span
                  class="search-item__logo search-item__logo--small"
                  v-if="supplier.satra"
                  v-html="svg.satraMember"
                ></span>
              </div>
            </div>
            <div class="search-item__info">
              <div class="search-item__info-header">
                <strong class="search-item__info-title">{{ supplier.title }}</strong>
              </div>
              <p class="search-item__info-address">{{ supplier.fullAddress }}</p>
              <p
                class="search-item__info-list"
                v-for="(capabilities, index) in getComponentData(supplier).slice(0, 2)"
                :key="index"
              >
                {{ capabilities }}{{ index === 1 && getComponentData(supplier).length > 2 ? '...' : '' }}
              </p>
            </div>
          </router-link>
        </div>
      </div>
      <div
        class="results__loader loader is-visible"
        v-if="suppliers.list && suppliers.list.length && suppliers.currentPage !== suppliers.totalPages"
        ref="loadingBar"
      ></div>
      <div v-if="suppliers.list && !suppliers.list.length" class="results__empty">No results</div>
      <suppliers-map class="results__map" :data="mapData" :countries="countries" />
    </div>
  </section>
</template>

<script>
import { imagePath } from '@lib/imgproxy';
import suppliersMap from 'apps/public/pages/components/map/suppliers-map.vue';
import footwearFilters from './suppliers-footwear-filters.vue';
import componentFilters from './suppliers-component-filters.vue';

// SVG
import fsMember from 'apps/common/assets/icons/other/fs-member.svg';
import searchIcon from '../img/search-icon.svg';
import menIcon from '../img/male-icon.svg';
import ladiesIcon from '../img/female-icon.svg';
// TODO: REVIEW if we really should statically import this one, since
// it might not be relevant(and thus causing unnecessary load)
import satraMember from 'apps/common/assets/external-organizations/satra-member.svg';

export default {
  components: { footwearFilters, componentFilters, suppliersMap },
  load({ store, route }) {
    // console.log(666);
    let url = `/api/suppliers?category=${route.meta.category}`;
    let urlQuery = route.fullPath.split('?')[1];
    let subscriptionUrl = `category=${route.meta.category}`;
    if (urlQuery) {
      url += `&${urlQuery}`;
      subscriptionUrl += `&${urlQuery}`;
    }

    let title;
    if (route.meta.category == 0) {
      title = 'Footwear Suppliers';
    } else if (route.meta.category == 1) {
      title = 'Component Suppliers';
    }

    store.head.title = `FindSourcing – ${title}`;

    return Promise.all([
      store.suppliers.loadList(`${url}&data=list`),
      store.suppliers.loadCoordinates(`${url}&data=coordinates`),
      store.suppliers.loadFilters(),
      store.subscriptions.checkStatus(subscriptionUrl),
    ]);
  },
  data() {
    return {
      loadingMore: false,
      loadingMoreFinished: false,
      svg: {
        fsMember,
        search: searchIcon,
        men: menIcon,
        ladies: ladiesIcon,
        satraMember,
      },
      filterActive: false,
    };
  },
  computed: {
    category() {
      return this.$route.meta.category;
    },
    requestUrl() {
      let url = `/api/suppliers?category=${this.$route.meta.category}`;
      let urlQuery = this.$route.fullPath.split('?')[1];
      if (urlQuery) url += `&${urlQuery}`;
      return url;
    },
    suppliers() {
      return this.$store.suppliers.list[`${this.requestUrl}&data=list`] || [];
    },
    countries() {
      let countries = this.$route.query.location;
      if (!countries) return [];

      if (typeof countries == 'string') {
        countries = [countries];
      }

      return countries;
    },
    mapData() {
      let data = this.$store.suppliers.coordinates[`${this.requestUrl}&data=coordinates`] || [];
      let coordinates = [];
      data.forEach((item) => {
        if (item.coordinates) {
          coordinates.push({
            id: item.id,
            latitude: item.coordinates.split(', ')[0],
            longitude: item.coordinates.split(', ')[1],
          });
        }
      });
      return coordinates;
    },
    subscriptionUrl() {
      let urlQuery = this.$route.fullPath.split('?')[1];
      let subscriptionUrl = `category=${this.$route.meta.category}`;
      if (urlQuery) {
        subscriptionUrl += `&${urlQuery}`;
      }
      return subscriptionUrl;
    },
    subscriptionStatus() {
      return this.$store.subscriptions.status;
    },
  },
  methods: {
    imagePath,
    fsMember(supplier) {
      return [2982, 31, 957].includes(supplier.id);
    },
    isLoadingBarVisible() {
      const loader = this.$refs.loadingBar;
      const offset = loader.getBoundingClientRect();
      const top = offset.top;
      const bottom = offset.bottom;

      const isVisible = top < window.innerHeight + 200 && bottom >= 0;

      return isVisible;
    },
    loadMore() {
      this.loadingMore = true;
      this.$store.suppliers.loadListNextPage(`${this.requestUrl}&data=list`).then((data) => {
        this.currentPage += 1;
        this.loadingMore = false;

        // TODO: Find other way to update lists
        this.$forceUpdate();
      });
    },
    handleScroll() {
      if (!this.$refs.loadingBar) return;
      if (this.isLoadingBarVisible() && !this.loadingMore) {
        this.loadMore();
      }
    },
    getTitles(ids) {
      return ids
        .split(',')
        .map((id) => this.$store.knowledgebank.titles.find((a) => a.id == id).title)
        .join(', ');
    },
    getComponentData(supplier) {
      const parentOptions = this.$store.suppliers.typeOfProduction
        .flatMap((filter) => filter.options)
        .filter((parentOption) => supplier[parentOption.key]);

      return parentOptions.map((parentOption) => {
        if (!parentOption.options) return parentOption.title;

        const checked = parentOption.options[0].options.filter((option) => supplier[option.key]);

        if (!checked) return parentOption.title;

        return parentOption.title + '; ' + checked.map((option) => option.title).join(', ');
      });
    },
    async toggleSubscription() {
      await this.$store.subscriptions.toggleSubscription(this.subscriptionUrl);
    },
  },
  watch: {
    '$route.query'() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss">
@use 'sass:math';

@import 'common/styles/variables';
@import 'common/styles/media-queries';
.suppliers-ui {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: math.div(4rem, 1.6);
  border-bottom: 1px solid rgba($c-dark, 0.1);
  position: fixed;
  width: 100%;
  top: math.div(4.5rem, 1.6);
  padding: 0 math.div(1.5rem, 1.6);
  z-index: 10004;
  transition: transform 0.2s ease, height 0.2s ease;
  background: $c-light;

  @include tablet-min {
    top: math.div(5.5rem, 1.6);
    height: math.div(5rem, 1.6);
  }
  @include tablet-landscape-min {
    padding: 0 math.div(3rem, 1.6);
  }
  @include desktop-min {
    top: math.div(7rem, 1.6);
    height: math.div(6rem, 1.6);
  }

  &.active {
    z-index: 16000004;
  }
}

.header--min + .main .suppliers-ui {
  @include desktop-min {
    transform: translateY(#{math.div(-1.5rem, 1.6)});
    height: math.div(5rem, 1.6);
  }
}

.filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  list-style: none;
  letter-spacing: 0.5px;
  @include tablet-min {
    justify-content: flex-start;
  }
  &__group {
    &-footer {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: math.div(5.5rem, 1.6);
      padding: math.div(1.5rem, 1.6) math.div(1.5rem, 1.6) math.div(2rem, 1.6);
      display: flex;
      justify-content: space-between;
      border-top: 1px solid rgba($c-dark, 0.1);
      background: $c-light;
      font-size: math.div(1.4rem, 1.6);
    }
    @include tablet-min {
      display: none;
      &--visible {
        display: flex;
        height: 100%;
        margin-left: math.div(2rem, 1.6);
      }
      &--visible &-items {
        display: flex;
        height: 100%;
      }
      &.active {
        display: block;
        .filters__group,
        .filters__label.hidden {
          display: block;
        }
        .filters__selected,
        .filters__counter {
          display: none;
        }
      }
    }
    &--container.active {
      @include tablet-min {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: calc(100vh - #{math.div(10.5rem, 1.6)});
        margin-top: 1px;
        background: $c-light;
        & > .filters__group-items {
          display: block;
          height: calc(100vh - #{math.div(16rem, 1.6)});
          padding: math.div(2rem, 1.6) math.div(3rem, 1.6);
          overflow: auto;
        }
      }
      @include tablet-landscape-min {
        width: 66%;
        box-shadow: math.div(50rem, 1.6) 0 math.div(100rem, 1.6) rgba($c-light, 0.6);
      }
      @include desktop-min {
        height: calc(100vh - #{math.div(12.9rem, 1.6)});
        .filters__group-items {
          height: calc(100vh - #{math.div(18.4rem, 1.6)});
        }
        .header--min + .main & {
          height: calc(100vh - #{math.div(10.5rem, 1.6)});
          .filters__group-items {
            height: calc(100vh - #{math.div(16rem, 1.6)});
          }
        }
      }
    }
  }
  &__list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 1px;
    padding: math.div(2rem, 1.6) math.div(1.5rem, 1.6);
    background: $c-light;
    height: calc(100vh - #{math.div(14rem, 1.6)});
    overflow: auto;
    &.active {
      display: block;
    }
    @include tablet-min {
      position: static;
      top: 0;
      height: math.div(5rem, 1.6);
      overflow: visible;
      display: flex;
      align-items: center;
      background: transparent;
      margin: 0;
      padding: 0;
      &.active {
        display: flex;
      }
    }
    @include desktop-min {
      height: math.div(6rem, 1.6);
    }
  }
  &__item {
    position: relative;
    line-height: math.div(2rem, 1.6);
    font-size: math.div(1.2rem, 1.6);
    font-weight: 600;
    @include tablet-min {
      &--dd {
        display: flex;
        height: 100%;
        align-items: center;
        &:not(:first-child) {
          margin-left: math.div(2rem, 1.6);
        }
      }
    }
  }
  &__item--dd &__select {
    display: flex;
    align-items: center;
    height: math.div(2rem, 1.6);
    opacity: 0.7;
    transition: opacity 0.2s ease;
    cursor: pointer;
    white-space: nowrap;
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      margin: 2px 0 0 math.div(0.5rem, 1.6);
      border-style: solid;
      border-width: math.div(0.4rem, 1.6) math.div(0.35rem, 1.6) 0 math.div(0.35rem, 1.6);
      border-color: $c-dark transparent transparent transparent;
      transition: transform 0.2s ease;
    }
    &.active:after {
      transform: scale(1, -1);
    }
    &:hover,
    &.active,
    &.selected {
      opacity: 1;
    }
  }
  &__label {
    @include tablet-min {
      &.hidden {
        display: none;
      }
    }
  }
  &__selected {
    display: none;
    @include tablet-min {
      display: block;
    }
  }
  &__counter {
    display: none;
    width: math.div(1.6rem, 1.6);
    height: math.div(1.6rem, 1.6);
    margin-left: 3px;
    border-radius: 50%;
    line-height: math.div(1.6rem, 1.6);
    font-size: math.div(1rem, 1.6);
    font-style: normal;
    background: $c-dark;
    text-align: center;
    color: $c-light;
    @include tablet-min {
      display: block;
    }
  }
  &__toggle &__counter {
    display: block;
  }

  &__box {
    padding: math.div(2rem, 1.6) 0 math.div(3rem, 1.6);
    margin: 0;
    max-width: math.div(50rem, 1.6);
    list-style: none;
    &-list {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 math.div(1rem, 1.6) 0 0;
      margin: 0;
      list-style: none;
      &-group {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        &:not(:first-child) {
          margin-top: math.div(1rem, 1.6);
        }

        &-wrapper {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          &:not(:first-child) {
            margin-top: math.div(1rem, 1.6);
            padding-top: math.div(1rem, 1.6) + math.div(0.6rem, 1.6);
            border-top: 1px solid $c-light-gray;
          }
        }
      }
      &-label {
        display: block;
        width: 100%;
        padding: 0 math.div(1rem, 1.6);
        background: $c-light-gray;
        line-height: math.div(2.6rem, 1.6);
        @include mobile-ls-min {
          margin-bottom: math.div(1rem, 1.6);
        }
      }
    }
    &-item {
      width: 100%;
      &:not(:first-child) {
        margin-top: math.div(0.8rem, 1.6);
      }
      @include mobile-ls-min {
        width: 50%;
        &:not(:first-child) {
          margin-top: 0;
        }
        .filters__box-list > &:nth-of-type(n + 3) {
          margin-top: math.div(0.8rem, 1.6);
        }
        .filters__box-list-group > &:nth-of-type(n + 4) {
          margin-top: math.div(0.8rem, 1.6);
        }
      }
    }
  }
  &__item--dd &__box {
    @include tablet-min {
      display: none;
      position: absolute;
      top: 100%;
      left: 0;
      min-width: math.div(25rem, 1.6);
      max-width: math.div(27rem, 1.6);
      padding: math.div(1.5rem, 1.6);
      margin-top: math.div(-0.5rem, 1.6);
      background: $c-light;
      box-shadow: 0 2px math.div(0.6rem, 1.6) 0 rgba(0, 0, 0, 0.3);
      &.active {
        display: block;
      }
    }
    @include desktop-min {
      margin-top: math.div(-1rem, 1.6);
    }
    &-list {
      @include tablet-min {
        max-height: math.div(27rem, 1.6);
        overflow: auto;
      }
      &-label {
        margin-bottom: 0;
      }
    }
    &-item {
      @include tablet-min {
        width: 100%;
        &:not(:first-child) {
          margin-top: math.div(1rem, 1.6);
        }
      }
    }
  }
  &__option {
    display: inline-block;
    cursor: pointer;
    user-select: none;
    &-checkbox {
      display: none;
    }
    &-radio {
      display: none;
    }
    &-name {
      position: relative;
      display: flex;
      align-items: center;
      white-space: nowrap;
      line-height: math.div(1.4rem, 1.6);
      font-size: math.div(1.2rem, 1.6);
      &:before {
        content: '';
        display: block;
        width: math.div(1.4rem, 1.6);
        height: math.div(1.4rem, 1.6);
        margin-right: math.div(0.6rem, 1.6);
        border-radius: 3px;
        border: 2px solid $c-blue;
        opacity: 0.7;
        transform: translateZ(0);
      }

      &--no-checkbox {
        &:before {
          display: none;
        }
      }
    }
    &-checkbox + &-name {
      &:after {
        content: '';
        position: absolute;
        top: math.div(0.5rem, 1.6);
        left: math.div(0.5rem, 1.6);
        width: math.div(0.6rem, 1.6);
        height: 3px;
        border-left: 2px solid $c-blue;
        border-bottom: 2px solid $c-blue;
        opacity: 0;
        transform: rotate(-45deg) scale(0.5);
        transition: transform 0.2s ease, opacity 0.2s ease;
      }
    }
    &-checkbox:checked + &-name:after {
      opacity: 1;
      transform: rotate(-45deg) scale(1);
    }
    &-radio + &-name {
      &:before {
        border-radius: 50%;
      }
      &:after {
        content: '';
        position: absolute;
        top: math.div(0.7rem, 1.6);
        left: math.div(0.7rem, 1.6);
        width: math.div(0.8rem, 1.6);
        height: math.div(0.8rem, 1.6);
        border-radius: 50%;
        background: $c-blue;
        opacity: 0;
        transform: scale(0.5);
        transition: transform 0.2s ease, opacity 0.2s ease;
      }
    }
    &-radio:checked + &-name:after {
      opacity: 1;
      transform: scale(1);
    }
  }
  &__controls,
  &__mobile-controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__controls {
    padding-top: math.div(1rem, 1.6);
  }
  &__mobile-controls {
    position: fixed;
    z-index: 3;
    left: 0;
    bottom: 0;
    height: math.div(5.5rem, 1.6);
    padding: math.div(1.5rem, 1.6) math.div(1.5rem, 1.6) math.div(2rem, 1.6);
    border-top: 1px solid rgba($c-dark, 0.1);
    background: $c-light;
    font-size: math.div(1.4rem, 1.6);
  }
  &__cancel {
    color: rgba($c-dark, 0.7);
  }
  &__clear {
    color: rgba($c-dark, 0.7);
  }
  &__apply {
    color: $c-blue;
  }
  &__input {
    position: relative;
    &-field {
      border: 0;
      outline: none;
      font-weight: bold;
      color: $c-dark;
      letter-spacing: 0.5px;
      padding-right: math.div(2rem, 1.6);
      font-size: math.div(1.4rem, 1.6);
      &::-webkit-input-placeholder {
        color: rgba($c-dark, 0.7);
      }
      &::-moz-input-placeholder {
        color: rgba($c-dark, 0.7);
      }
    }
    &-icon {
      height: math.div(1.2rem, 1.6);
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: math.div(-0.6rem, 1.6);
      pointer-events: none;
      fill: $c-dark;
      opacity: 0.7;
    }
  }
  &__toggle {
    display: flex;
    align-items: center;
    opacity: 0.7;
    padding: 0;
    margin-left: auto;
    background-color: transparent;
    border: 0;
    transition: opacity 0.2s ease;
    cursor: pointer;
    white-space: nowrap;
    outline: none;
    font-weight: bold;
    color: $c-dark;
    &:after {
      content: '';
      display: block;
      width: 0;
      height: 0;
      margin: 0 0 0 math.div(1rem, 1.6);
      border-style: solid;
      border-width: math.div(0.4rem, 1.6) math.div(0.35rem, 1.6) 0 math.div(0.35rem, 1.6);
      border-color: $c-dark transparent transparent transparent;
      transition: transform 0.2s ease;
    }
    &.active:after {
      transform: scale(1, -1);
    }
    &:hover,
    &.active,
    &.selected {
      opacity: 1;
    }
    @include tablet-min {
      display: none;
    }
  }
  &__input {
    display: block;
    width: 100%;
    padding: math.div(0.7rem, 1.6);
    margin-bottom: math.div(3rem, 1.6);
    height: math.div(2.6rem, 1.6);
    border: 1px solid rgba($c-dark, 0.2);
    font-size: math.div(1.2rem, 1.6);
    font-weight: 600;
    color: $c-dark;
    @include tablet-min {
      margin-bottom: 0;
    }
  }
}

.subscribe-button {
  border: 1px solid $c-blue;
  background-color: transparent;
  padding: math.div(0.5rem, 1.6) math.div(1rem, 1.6);
  font-size: math.div(1.2rem, 1.6);
  letter-spacing: 0.3px;
  outline: none;
  color: $c-blue;
  transition: color 0.5s, background-color 0.5s, border-color 0.5s;
  &:hover {
    cursor: pointer;
    border-color: $c-dark;
    color: $c-dark;
  }
  &.is-subscribed {
    color: $c-light;
    background-color: $c-blue;
    border-color: $c-blue;
  }
}

.results {
  position: relative;
  padding-top: math.div(3.75rem, 1.6);
  @include tablet-min {
    padding-top: math.div(5rem, 1.6);
  }
  @include tablet-landscape-min {
  }
  @include desktop-min {
    padding-top: math.div(6rem, 1.6);
  }
  &__empty {
    padding: math.div(1.5rem, 1.6);
    @include tablet-landscape-min {
      padding: math.div(3rem, 1.6);
    }
  }
  &__loader.loader {
    position: relative;
    height: math.div(14rem, 1.6);
    @include tablet-landscape-min {
      width: 66%;
      padding: math.div(1.5rem, 1.6);
    }
  }
  &__list {
    display: flex;
    position: relative;
    flex-wrap: wrap;
    padding: 0 math.div(1.5rem, 1.6);
    @include mobile-ls-min {
      padding: math.div(0.75rem, 1.6);
    }
    @include tablet-landscape-min {
      width: 66%;
      padding: math.div(1.5rem, 1.6);
    }
  }
  &__item {
    position: relative;
    width: 100%;
    padding: math.div(1.5rem, 1.6) 0;
    @include mobile-ls-min {
      width: 50%;
      padding: math.div(0.75rem, 1.6);
    }
    @include tablet-landscape-min {
      padding: math.div(1.5rem, 1.6);
    }
    @include desktop-large-min {
      width: 33.3333333%;
      padding: math.div(1.5rem, 1.6);
    }

    &--member {
      &:before {
        content: '';
        position: absolute;
        top: math.div(0.5rem, 1.6);
        bottom: math.div(0.5rem, 1.6);
        left: math.div(-0.7rem, 1.6);
        right: math.div(-0.7rem, 1.6);
        border: 2px solid $c-dark;

        @include mobile-ls-min {
          top: 2px;
          bottom: 0;
          left: 2px;
          right: 2px;
        }
        @include tablet-landscape-min {
          top: math.div(0.5rem, 1.6);
          bottom: math.div(0.5rem, 1.6);
          left: math.div(0.5rem, 1.6);
          right: math.div(0.5rem, 1.6);
        }
      }
    }
  }
  &__map {
    display: none;
    transition: height 0.2s ease;
    @include tablet-landscape-min {
      display: block;
      position: fixed;
      bottom: 0;
      right: 0;
      z-index: 3;
      width: 34%;
      height: calc(100vh - #{math.div(10.5rem, 1.6)});
      background: $c-light-gray;
    }
    @include desktop-min {
      height: calc(100vh - #{math.div(13rem, 1.6)});
    }
  }
}
.header--min + .main .results__map {
  @include desktop-min {
    height: calc(100vh - #{math.div(10.5rem, 1.6)});
  }
}

.search-item {
  color: $c-dark;
  &:hover,
  &:active {
    color: $c-dark;
  }
  &__photo {
    position: relative;
    background-color: rgba($c-dark, 0.05);
    &:before {
      content: '';
      display: block;
      width: 100%;
      padding-bottom: 40%;
    }
    &-bg {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: 50% 50%;
    }
  }
  &__logos {
    position: absolute;
    right: 0.625rem;
    bottom: 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
  &__logo {
    height: 6rem;
    width: 6rem;
    box-sizing: content-box;
    padding: 0 0.25rem;

    &--small {
      height: 4.25rem;
      width: 4.25rem;
    }
  }
  &__info {
    padding-top: math.div(1rem, 1.6);
    font-size: math.div(1.4rem, 1.6);
    letter-spacing: 0.5px;
    &-header {
      display: flex;
    }
    &-title {
      flex-grow: 1;
      font-size: math.div(1.6rem, 1.6);
    }
    &-icon {
      width: math.div(1.4rem, 1.6);
      height: math.div(1.4rem, 1.6);
      margin-left: math.div(0.7rem, 1.6);
      fill: $c-dark;
    }
    &-address {
      font-style: italic;
      margin: math.div(0.5rem, 1.6) 0 math.div(1rem, 1.6);
    }
    &-list {
      margin: 3px 0;
      font-weight: 600;
    }
  }
}
</style>
