<template>
  <div class="filters">
    <div class="filters__list" ref="filtersFullList" :class="{ active: activeFilters }">
      <div class="filters__item">
        <form action="#" @submit.prevent="applyFilters">
          <input class="filters__input" type="text" placeholder="Filter by name" v-model.trim="supplierName" />
        </form>
      </div>
      <div
        v-if="$store.user.loggedIn"
        class="filters__item"
        :class="{ 'filters__item--dd': breakPoint == 'tablet' || breakPoint == 'desktop' || breakPoint == 'desktop-l' }"
      >
        <div
          class="filters__select selected"
          :class="{ active: activeFilter == 'supplierCategory' }"
          @click.stop="setActiveFilter('supplierCategory')"
        >
          <span class="filters__selected"> Component </span>
        </div>
        <div class="filters__box" :class="{ active: activeFilter == 'supplierCategory' }" @click.stop>
          <ul class="filters__box-list">
            <li class="filters__box-item">
              <label class="filters__option" @click="changeCategory()">
                <span class="filters__option-name filters__option-name--no-checkbox">Footwear</span>
              </label>
            </li>
            <li class="filters__box-item">
              <label class="filters__option" @click="cancelFilters()">
                <span class="filters__option-name filters__option-name--no-checkbox">Component</span>
              </label>
            </li>
          </ul>
          <div v-if="breakPoint != 'mobile'" class="filters__controls">
            <a href="#" class="filters__cancel" @click.prevent="cancelFilters()">Cancel</a>
          </div>
        </div>
      </div>
      <div
        class="filters__item"
        :class="{ 'filters__item--dd': breakPoint == 'tablet' || breakPoint == 'desktop' || breakPoint == 'desktop-l' }"
      >
        <div
          class="filters__select"
          :class="{ active: activeFilter == 'location', selected: locationFilters.counter > 0 }"
          @click.stop="setActiveFilter('location')"
        >
          <span class="filters__label" :class="{ hidden: locationFilters.counter }"> Location </span>
          <span v-if="locationFilters.counter" class="filters__selected">
            {{ locationFilters.label }}
          </span>
          <span v-if="locationFilters.counter > 1" class="filters__counter">
            {{ locationFilters.counter }}
          </span>
        </div>
        <div class="filters__box" :class="{ active: activeFilter == 'location' }" @click.stop>
          <ul class="filters__box-list" ref="locationList">
            <li class="filters__box-item" v-for="(item, index) in filters.location" :key="index">
              <label class="filters__option">
                <input type="checkbox" class="filters__option-checkbox" v-model="item.checked" />
                <span class="filters__option-name">{{ item.title }}</span>
              </label>
            </li>
          </ul>
          <div v-if="breakPoint != 'mobile'" class="filters__controls">
            <a
              v-if="locationFiltersChanged || !locationFilters.query"
              href="#"
              class="filters__cancel"
              @click.prevent="cancelFilters()"
              >Cancel</a
            >
            <a v-else href="#" class="filters__clear" @click.prevent="clearFilters('location')">Clear</a>
            <a
              href="#"
              class="filters__apply"
              @click.prevent="
                applyFilters();
                activeFilter = '';
              "
              >Apply</a
            >
          </div>
        </div>
      </div>

      <div
        class="filters__item"
        :class="{ 'filters__item--dd': breakPoint == 'tablet' || breakPoint == 'desktop' || breakPoint == 'desktop-l' }"
        v-for="(filter, i) in $store.suppliers.typeOfProduction.slice(0, 1)"
        :key="i"
      >
        <div
          class="filters__select"
          :class="{ active: activeFilter == filter.key, selected: materialFilters[filter.key].counter > 0 }"
          @click.stop="setActiveFilter(filter.key)"
        >
          <span class="filters__label" :class="{ hidden: materialFilters[filter.key].counter }">
            {{ filter.title }}
          </span>
          <span v-if="materialFilters[filter.key].counter" class="filters__selected">
            {{ materialFilters[filter.key].label }}
          </span>
          <span v-if="materialFilters[filter.key].counter > 1" class="filters__counter">
            {{ materialFilters[filter.key].counter }}
          </span>
        </div>
        <div class="filters__box" :class="{ active: activeFilter == filter.key }" @click.stop>
          <div class="filters__box-list">
            <div
              v-if="!group.group"
              class="filters__box-list-group-wrapper"
              v-for="(group, i) in filters[filter.key]"
              :key="i"
            >
              <div class="filters__box-item">
                <label class="filters__option filters__option">
                  <input
                    type="checkbox"
                    class="filters__option-checkbox"
                    v-model="group.checked"
                    @change="
                      resetUnderlyingFilters({ filterKey: filter.key, groupKey: group.key, checked: group.checked })
                    "
                  />
                  <span class="filters__option-name">{{ group.title }}</span>
                </label>
                <div
                  v-if="item.group == group.key && item.first"
                  class="filters__box-list-group"
                  v-for="(item, i) in filters[filter.key]"
                  :key="item.title"
                  :style="group.checked ? {} : { display: 'none' }"
                >
                  <div class="filters__box-list-label">{{ item.category }}</div>
                  <div
                    class="filters__box-item"
                    v-for="item in filters[filter.key].filter(
                      (i) => i.category == item.category && i.group == group.key
                    )"
                    :key="item.title"
                  >
                    <label class="filters__option filters__option">
                      <input type="checkbox" class="filters__option-checkbox" v-model="item.checked" />
                      <span class="filters__option-name">{{ item.title }}</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="false && (i == 0 || item.category != filters[filter.key][i - 1].category)"
              class="filters__box-list-group"
              v-for="(item, i) in filters[filter.key]"
              :key="item.title"
            >
              <div class="filters__box-list-label">{{ item.category }}</div>
              <div
                class="filters__box-item"
                v-for="item in filters[filter.key].filter((i) => i.category == item.category)"
                :key="item.title"
              >
                <label class="filters__option filters__option">
                  <input type="checkbox" class="filters__option-checkbox" v-model="item.checked" />
                  <span class="filters__option-name">{{ item.title }}</span>
                </label>
              </div>
            </div>
          </div>
          <div v-if="breakPoint != 'mobile' && breakPoint != 'tablet'" class="filters__controls">
            <a
              v-if="materialFiltersChanged[filter.key] || !materialFilters[filter.key].query"
              href="#"
              class="filters__cancel"
              @click.prevent="cancelFilters()"
              >Cancel</a
            >
            <a v-else href="#" class="filters__clear" @click.prevent="clearFilters(filter.key)">Clear</a>
            <a
              href="#"
              class="filters__apply"
              @click.prevent="
                applyFilters();
                activeFilter = '';
              "
              >Apply</a
            >
          </div>
        </div>
      </div>

      <div
        class="filters__group"
        :class="{
          'filters__group--visible': breakPoint == 'desktop' || breakPoint == 'desktop-l',
          'filters__group--container': breakPoint == 'tablet',
          active: activeFilter == 'moreFilters' && breakPoint == 'tablet',
        }"
        :ref="breakPoint == 'tablet' ? 'moreFiltersList' : ''"
      >
        <div class="filters__group-items" @click.stop>
          <div
            class="filters__item"
            :class="{ 'filters__item--dd': breakPoint == 'desktop' || breakPoint == 'desktop-l' }"
            v-for="(filter, i) in $store.suppliers.typeOfProduction.slice(1)"
            :key="i"
          >
            <div
              class="filters__select"
              :class="{ active: activeFilter == filter.key, selected: materialFilters[filter.key].counter > 0 }"
              @click.stop="setActiveFilter(filter.key)"
            >
              <span class="filters__label" :class="{ hidden: materialFilters[filter.key].counter }">
                {{ filter.title }}
              </span>
              <span v-if="materialFilters[filter.key].counter" class="filters__selected">
                {{ materialFilters[filter.key].label }}
              </span>
              <span v-if="materialFilters[filter.key].counter > 1" class="filters__counter">
                {{ materialFilters[filter.key].counter }}
              </span>
            </div>
            <div class="filters__box" :class="{ active: activeFilter == filter.key }" @click.stop>
              <div class="filters__box-list">
                <div
                  v-if="!group.group"
                  class="filters__box-list-group-wrapper"
                  v-for="(group, i) in filters[filter.key]"
                  :key="i"
                >
                  <div class="filters__box-item">
                    <label class="filters__option filters__option">
                      <input
                        type="checkbox"
                        class="filters__option-checkbox"
                        v-model="group.checked"
                        @change="
                          resetUnderlyingFilters({ filterKey: filter.key, groupKey: group.key, checked: group.checked })
                        "
                      />
                      <span class="filters__option-name">{{ group.title }}</span>
                    </label>
                    <div
                      v-if="item.group == group.key && item.first"
                      class="filters__box-list-group"
                      v-for="(item, i) in filters[filter.key]"
                      :key="i"
                      :style="group.checked ? {} : { display: 'none' }"
                    >
                      <div class="filters__box-list-label">{{ item.category }}</div>
                      <div
                        class="filters__box-item"
                        v-for="item in filters[filter.key].filter(
                          (i) => i.category == item.category && i.group == group.key
                        )"
                        :key="item.title"
                      >
                        <label class="filters__option filters__option">
                          <input type="checkbox" class="filters__option-checkbox" v-model="item.checked" />
                          <span class="filters__option-name">{{ item.title }}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="false && (i == 0 || item.category != filters[filter.key][i - 1].category)"
                  class="filters__box-list-group"
                  v-for="(item, i) in filters[filter.key]"
                  :key="i"
                >
                  <div class="filters__box-list-label">{{ item.category }}</div>
                  <div
                    class="filters__box-item"
                    v-for="(item, i) in filters[filter.key].filter((i) => i.category == item.category)"
                    :key="i"
                  >
                    <label class="filters__option filters__option">
                      <input type="checkbox" class="filters__option-checkbox" v-model="item.checked" />
                      <span class="filters__option-name">{{ item.title }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div v-if="breakPoint != 'mobile' && breakPoint != 'tablet'" class="filters__controls">
                <a
                  v-if="materialFiltersChanged[filter.key] || !materialFilters[filter.key].query"
                  href="#"
                  class="filters__cancel"
                  @click.prevent="cancelFilters()"
                  >Cancel</a
                >
                <a v-else href="#" class="filters__clear" @click.prevent="clearFilters(filter.key)">Clear</a>
                <a
                  href="#"
                  class="filters__apply"
                  @click.prevent="
                    applyFilters();
                    activeFilter = '';
                  "
                  >Apply</a
                >
              </div>
            </div>
          </div>

          <div
            class="filters__item"
            :class="{ 'filters__item--dd': breakPoint == 'desktop' || breakPoint == 'desktop-l' }"
          >
            <div
              class="filters__select"
              :class="{ active: activeFilter == 'associations', selected: associationsFilters.counter > 0 }"
              @click.stop="setActiveFilter('associations')"
            >
              <span class="filters__label" :class="{ hidden: associationsFilters.counter }"> Associations </span>
              <span v-if="associationsFilters.counter" class="filters__selected">
                {{ associationsFilters.label }}
              </span>
              <span v-if="associationsFilters.counter > 1" class="filters__counter">
                {{ associationsFilters.counter }}
              </span>
            </div>
            <div class="filters__box" :class="{ active: activeFilter == 'associations' }" @click.stop>
              <ul class="filters__box-list" ref="associationsList">
                <li class="filters__box-item" v-for="(item, i) in filters.associations" :key="i">
                  <label class="filters__option">
                    <input type="checkbox" class="filters__option-checkbox" v-model="item.checked" />
                    <span class="filters__option-name">{{ item.title }}</span>
                  </label>
                </li>
              </ul>
              <div v-if="breakPoint != 'mobile' && breakPoint != 'tablet'" class="filters__controls">
                <a
                  v-if="associationsFiltersChanged || !associationsFilters.query"
                  href="#"
                  class="filters__cancel"
                  @click.prevent="cancelFilters()"
                  >Cancel</a
                >
                <a v-else href="#" class="filters__clear" @click.prevent="clearFilters('associations')">Clear</a>
                <a
                  href="#"
                  class="filters__apply"
                  @click.prevent="
                    applyFilters();
                    activeFilter = '';
                  "
                  >Apply</a
                >
              </div>
            </div>
          </div>

          <div v-if="breakPoint == 'tablet'" class="filters__group-footer">
            <a
              v-if="filtersChanged || !appliedFilters"
              href="#"
              class="filters__cancel"
              @click.prevent="
                cancelFilters();
                activeFilter = '';
              "
            >
              Cancel
            </a>
            <a v-else href="#" class="filters__cancel" @click.prevent="clearMoreFilters()"> Clear </a>
            <a
              href="#"
              class="filters__apply"
              @click.prevent="
                applyFilters();
                activeFilter = '';
              "
            >
              Apply
            </a>
          </div>
        </div>
      </div>

      <div
        v-if="breakPoint != 'mobile' && breakPoint != 'desktop' && breakPoint != 'desktop-l'"
        class="filters__item filters__item--dd"
        @click.stop="setActiveFilter('moreFilters')"
      >
        <div
          class="filters__select"
          :class="{ active: activeFilter == 'moreFilters', selected: moreFiltersCounter > 0 }"
        >
          <span class="filters__label"> More Filters </span>
          <span v-if="moreFiltersCounter" class="filters__counter">
            {{ moreFiltersCounter }}
          </span>
        </div>
      </div>

      <div v-if="breakPoint == 'mobile'" class="filters__mobile-controls">
        <a
          v-if="filtersChanged || !appliedFilters"
          href="#"
          class="filters__cancel"
          @click.prevent="
            cancelFilters();
            $emit('update:activeFilters', false);
          "
        >
          Cancel
        </a>
        <a
          v-else
          href="#"
          class="filters__cancel"
          @click.prevent="
            $router.push({ name: $route.name, query: null });
            $emit('update:activeFilters', false);
          "
        >
          Clear
        </a>
        <a
          href="#"
          class="filters__apply"
          @click.prevent="
            applyFilters();
            $emit('update:activeFilters', false);
          "
        >
          Apply
        </a>
      </div>
    </div>
    <button
      class="filters__toggle"
      :class="{ active: activeFilters, selected: countAllFilters }"
      @click="
        applyFilters();
        $emit('update:activeFilters', !activeFilters);
      "
    >
      <span class="filters__toggle-label">Filters</span>
      <span v-if="countAllFilters" class="filters__counter">
        {{ countAllFilters }}
      </span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    activeFilters: {
      default: false,
    },
  },
  data() {
    const materialFilters = {};

    this.$store.suppliers.typeOfProduction.forEach((filter) => {
      const options = [];

      // For each group
      filter.options.forEach((parentOption) => {
        // Main option
        options.push({
          title: parentOption.title,
          key: parentOption.key,
          category: null,
          checked: false,
        });

        parentOption.options?.forEach((category) => {
          // Map each option into array
          category.options
            .map((option, i) => {
              return {
                title: option.title,
                first: i == 0,
                key: option.key,
                group: parentOption.key,
                category: category.groupTitle,
                checked: false,
              };
            })
            .forEach((i) => options.push(i)); // And add to array
        });
      });

      materialFilters[filter.key] = options;
    });

    return {
      activeFilter: '',
      breakPoint: 'mobile',
      filters: {
        location: [],
        ...materialFilters,
        associations: [
          { title: 'SATRA', key: 'satra', checked: false },
          { title: 'CICEG', key: 'ciceg', checked: false },
        ],
      },
      supplierName: null,
      appliedFilters: {},
    };
  },
  computed: {
    supplierNameFilters() {
      return this.checkActiveFilters(null, 'supplierName');
    },
    supplierNameFiltersChanged() {
      return this.checkIfFilterChanged('supplierName', this.supplierNameFilters.query);
    },
    locationFilters() {
      return this.checkActiveFilters(this.filters.location, 'location');
    },
    locationFiltersChanged() {
      return this.checkIfFilterChanged('location', this.locationFilters.query);
    },
    materialFilters() {
      return Object.fromEntries(
        this.$store.suppliers.typeOfProduction.map((filter) => {
          return [filter.key, this.checkActiveFilters(this.filters[filter.key], filter.key)];
        })
      );
    },
    materialFiltersChanged() {
      return Object.fromEntries(
        this.$store.suppliers.typeOfProduction.map((filter) => {
          return [filter.key, this.checkIfFilterChanged(filter.key, this.materialFilters[filter.key].query)];
        })
      );
    },
    associationsFilters() {
      return this.checkActiveFilters(this.filters.associations, 'associations');
    },
    associationsFiltersChanged() {
      return this.checkIfFilterChanged('associations', this.associationsFilters.query);
    },
    filtersChanged() {
      return (
        this.supplierNameFiltersChanged ||
        this.locationFiltersChanged ||
        Object.values(this.materialFiltersChanged).some((changed) => changed) ||
        this.associationsFiltersChanged
      );
    },
    countAllFilters() {
      return this.supplierNameFilters.counter + this.locationFilters.counter + this.associationsFilters.counter;
    },
    moreFiltersCounter() {
      return (
        this.associationsFilters.counter +
        Object.values(this.materialFilters)
          .slice(1)
          .reduce((accumulator, filters) => accumulator + filters.counter, 0)
      );
    },
  },
  methods: {
    getFilters() {
      let f = this.$store.suppliers.filters.componentSuppliersFilters;
      // Countries
      this.filters.location = f.countries.map((i) => {
        return {
          title: i,
          checked: false,
        };
      });

      // Sync filters after all values are fetched
      this.getAppliedFilters();
    },
    setActiveFilter(key = '') {
      if (this.mobile) return;
      if (this.activeFilter == key) {
        this.activeFilter = '';
      } else {
        this.activeFilter = key;
        if (key && this.$refs[`${key}List`]) this.$refs[`${key}List`].scrollTop = 0;
      }
    },
    setActiveFilterListener() {
      this.setActiveFilter();
      if (this.breakPoint != 'mobile') this.applyFilters();
    },
    checkWidth() {
      if (window.innerWidth < 768) {
        this.breakPoint = 'mobile';
      } else if (window.innerWidth < 1200) {
        this.breakPoint = 'tablet';
      } else if (window.innerWidth < 1600) {
        this.breakPoint = 'desktop';
      } else {
        this.breakPoint = 'desktop-l';
      }
    },
    getAppliedFilters() {
      this.appliedFilters = Object.keys(this.$route.query).length ? this.$route.query : null;
      this.syncFilters(this.appliedFilters);
    },
    checkActiveFilters(group, name) {
      if (name == 'supplierName') {
        return {
          name,
          counter: this.supplierName ? 1 : 0,
          query: this.supplierName || null,
        };
      }
      if (!group) return;

      // Checkbox filters
      let filters = [],
        counter = 0,
        label;
      group.forEach((f) => {
        if (f.checked) {
          if (!label) label = f.title;
          filters.push(f.key || f.title);
          counter++;
        }
      });
      return {
        name,
        label,
        counter,
        query: counter && counter > 1 ? filters : filters[0],
      };
    },
    setFilterValue(filter, title) {
      // Unknown query string parameter sent in as filter
      if (!this.filters[filter]) return;

      const identifier = this.$store.suppliers.typeOfProduction
        .map((filter) => filter.key)
        .concat(['associations'])
        .includes(filter)
        ? 'key'
        : 'title';

      this.filters[filter].forEach((i) => {
        if (i[identifier] === title) {
          i.checked = true;
        }
      });
    },
    resetFilters() {
      this.supplierName = null;
      for (let key in this.filters) {
        this.filters[key].forEach((i) => {
          i.checked = false;
        });
      }
    },
    syncFilters(filters) {
      this.resetFilters();
      if (filters) {
        for (let key in filters) {
          if (key == 'supplierName') {
            this.supplierName = filters['supplierName'];
          } else if (typeof filters[key] == 'string') {
            this.setFilterValue(key, filters[key]);
          } else {
            filters[key].forEach((i) => {
              this.setFilterValue(key, i);
            });
          }
        }
      }
    },
    cancelFilters() {
      this.getAppliedFilters();
      this.activeFilter = '';
    },
    clearFilter(name) {
      this.filters[name].forEach((f) => (f.checked = false));
    },
    clearFilters(names) {
      this.activeFilter = '';
      if (typeof names == 'string') {
        this.clearFilter(names);
      } else {
        names.forEach((name) => this.clearFilter(name));
      }
      this.$router.push({ name: this.$route.name, query: this.filtersToAppy() });
    },
    applyFilters() {
      let query = this.filtersToAppy();

      // Check whether the same filters have been applied, then do nothing
      // TODO: REFACTOR
      if (
        JSON.stringify(query) === JSON.stringify(this.appliedFilters) ||
        (!Object.keys(query).length && !this.appliedFilters)
      )
        return;

      this.activeFilter = '';
      this.$router.push({ name: this.$route.name, query: query });
    },
    filtersToAppy() {
      let obj = {};

      [
        this.supplierNameFilters,
        this.locationFilters,
        ...Object.values(this.materialFilters),
        this.associationsFilters,
      ].forEach((filter) => {
        if (filter.counter) {
          obj[filter.name] = filter.query;
        }
      });

      return obj;
    },
    checkIfFilterChanged(slug, selected) {
      let applied = this.appliedFilters;

      // False if both values are not defined
      if ((!applied || !applied[slug]) && !selected) return false;

      // True if one value is defind and other is not.
      // True if values have different data types
      if ((!applied && selected) || typeof applied[slug] !== typeof selected) return true;

      // Compare values (string, number / array)
      if (typeof selected === 'string' || typeof selected === 'number') {
        return applied[slug] !== selected;
      } else {
        let check =
          applied[slug].length == selected.length &&
          applied[slug].every((e, i) => {
            return e === selected[i];
          });
        return !check;
      }
    },
    resetUnderlyingFilters({ filterKey, groupKey, checked }) {
      if (checked) return;

      // Uncheck any checked underlying filter
      this.filters[filterKey].filter((i) => i.group == groupKey && i.checked).forEach((i) => (i.checked = false));
    },
    changeCategory() {
      this.$router.push({ name: 'footwearSuppliers' });
    },
  },
  watch: {
    '$route.query'() {
      this.getAppliedFilters();
    },
    activeFilters(value) {
      if (value) {
        setTimeout(() => {
          // Trigger range slider
          window.dispatchEvent(new Event('resize'));
          // Scroll to top
          this.$refs['filtersFullList'].scrollTop = 0;
        }, 50);
      }
    },
  },
  mounted() {
    this.checkWidth();
    this.getFilters();
    document.body.addEventListener('click', this.setActiveFilterListener);
    window.addEventListener('resize', this.checkWidth);
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.setActiveFilterListener);
    window.removeEventListener('resize', this.checkWidth);
  },
};
</script>
