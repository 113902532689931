<template>
  <div class="filters">
    <div class="filters__list" ref="filtersFullList" :class="{ active: activeFilters }">
      <div class="filters__item">
        <form action="#" @submit.prevent="applyFilters">
          <input class="filters__input" type="text" placeholder="Filter by name" v-model.trim="supplierName" />
        </form>
      </div>
      <div
        v-if="$store.user.loggedIn"
        class="filters__item"
        :class="{ 'filters__item--dd': breakPoint == 'tablet' || breakPoint == 'desktop' || breakPoint == 'desktop-l' }"
      >
        <div
          class="filters__select selected"
          :class="{ active: activeFilter == 'supplierCategory' }"
          @click.stop="setActiveFilter('supplierCategory')"
        >
          <span class="filters__selected"> Footwear </span>
        </div>
        <div class="filters__box" :class="{ active: activeFilter == 'supplierCategory' }" @click.stop>
          <ul class="filters__box-list">
            <li class="filters__box-item">
              <label class="filters__option" @click="cancelFilters()">
                <span class="filters__option-name filters__option-name--no-checkbox">Footwear</span>
              </label>
            </li>
            <li class="filters__box-item">
              <label class="filters__option" @click="changeCategory()">
                <span class="filters__option-name filters__option-name--no-checkbox">Component</span>
              </label>
            </li>
          </ul>
          <div v-if="breakPoint != 'mobile'" class="filters__controls">
            <a href="#" class="filters__cancel" @click.prevent="cancelFilters()">Cancel</a>
          </div>
        </div>
      </div>
      <div
        class="filters__item"
        :class="{ 'filters__item--dd': breakPoint == 'tablet' || breakPoint == 'desktop' || breakPoint == 'desktop-l' }"
      >
        <div
          class="filters__select"
          :class="{ active: activeFilter == 'footwearTypes', selected: footwearTypesFilters.counter }"
          @click.stop="setActiveFilter('footwearTypes')"
        >
          <span class="filters__label" :class="{ hidden: footwearTypesFilters.counter }"> Footwear Types </span>
          <span v-if="footwearTypesFilters.counter" class="filters__selected">
            {{ footwearTypesFilters.label }}
          </span>
          <span v-if="footwearTypesFilters.counter > 1" class="filters__counter">
            {{ footwearTypesFilters.counter }}
          </span>
        </div>
        <div class="filters__box" :class="{ active: activeFilter == 'footwearTypes' }" @click.stop>
          <ul class="filters__box-list" ref="footwearTypesList">
            <li class="filters__box-item" v-for="item in filters.footwearTypes" :key="item">
              <label class="filters__option">
                <input type="checkbox" class="filters__option-checkbox" v-model="item.checked" />
                <span class="filters__option-name">{{ item.title }}</span>
              </label>
            </li>
          </ul>
          <div v-if="breakPoint != 'mobile'" class="filters__controls">
            <a
              v-if="footwearTypesFiltersChanged || !footwearTypesFilters.query"
              href="#"
              class="filters__cancel"
              @click.prevent="cancelFilters()"
              >Cancel</a
            >
            <a v-else href="#" class="filters__clear" @click.prevent="clearFilters('footwearTypes')">Clear</a>
            <a
              href="#"
              class="filters__apply"
              @click.prevent="
                applyFilters();
                activeFilter = '';
              "
              >Apply</a
            >
          </div>
        </div>
      </div>
      <div
        class="filters__item"
        :class="{ 'filters__item--dd': breakPoint == 'tablet' || breakPoint == 'desktop' || breakPoint == 'desktop-l' }"
      >
        <div
          class="filters__select"
          :class="{ active: activeFilter == 'gender', selected: genderFilters.counter > 0 }"
          @click.stop="setActiveFilter('gender')"
        >
          <span class="filters__label" :class="{ hidden: genderFilters.counter }"> Gender </span>
          <span v-if="genderFilters.counter" class="filters__selected">
            {{ genderFilters.label }}
          </span>
          <span v-if="genderFilters.counter > 1" class="filters__counter">
            {{ genderFilters.counter }}
          </span>
        </div>
        <div class="filters__box" :class="{ active: activeFilter == 'gender' }" @click.stop>
          <ul class="filters__box-list" ref="genderList">
            <li class="filters__box-item" v-for="(item, index) in filters.gender" :key="index">
              <label class="filters__option">
                <input type="checkbox" class="filters__option-checkbox" v-model="item.checked" />
                <span class="filters__option-name">{{ item.title }}</span>
              </label>
            </li>
          </ul>
          <div v-if="breakPoint != 'mobile'" class="filters__controls">
            <a
              v-if="genderFiltersChanged || !genderFilters.query"
              href="#"
              class="filters__cancel"
              @click.prevent="cancelFilters()"
              >Cancel</a
            >
            <a v-else href="#" class="filters__clear" @click.prevent="clearFilters('gender')">Clear</a>
            <a
              href="#"
              class="filters__apply"
              @click.prevent="
                applyFilters();
                activeFilter = '';
              "
              >Apply</a
            >
          </div>
        </div>
      </div>
      <div
        class="filters__item"
        :class="{ 'filters__item--dd': breakPoint == 'tablet' || breakPoint == 'desktop' || breakPoint == 'desktop-l' }"
      >
        <div
          class="filters__select"
          :class="{ active: activeFilter == 'location', selected: locationFilters.counter > 0 }"
          @click.stop="setActiveFilter('location')"
        >
          <span class="filters__label" :class="{ hidden: locationFilters.counter }"> Location </span>
          <span v-if="locationFilters.counter" class="filters__selected">
            {{ locationFilters.label }}
          </span>
          <span v-if="locationFilters.counter > 1" class="filters__counter">
            {{ locationFilters.counter }}
          </span>
        </div>
        <div class="filters__box" :class="{ active: activeFilter == 'location' }" @click.stop>
          <ul class="filters__box-list" ref="locationList">
            <li class="filters__box-item" v-for="(item, index) in filters.location" :key="index">
              <label class="filters__option">
                <input type="checkbox" class="filters__option-checkbox" v-model="item.checked" />
                <span class="filters__option-name">{{ item.title }}</span>
              </label>
            </li>
          </ul>
          <div v-if="breakPoint != 'mobile'" class="filters__controls">
            <a
              v-if="locationFiltersChanged || !locationFilters.query"
              href="#"
              class="filters__cancel"
              @click.prevent="cancelFilters()"
              >Cancel</a
            >
            <a v-else href="#" class="filters__clear" @click.prevent="clearFilters('location')">Clear</a>
            <a
              href="#"
              class="filters__apply"
              @click.prevent="
                applyFilters();
                activeFilter = '';
              "
              >Apply</a
            >
          </div>
        </div>
      </div>

      <div
        class="filters__group"
        :class="{
          'filters__group--visible': breakPoint == 'desktop' || breakPoint == 'desktop-l',
          'filters__group--container': breakPoint == 'tablet',
          active: activeFilter == 'moreFilters' && breakPoint == 'tablet',
        }"
        :ref="breakPoint == 'tablet' ? 'moreFiltersList' : ''"
      >
        <div class="filters__group-items" @click.stop>
          <div
            class="filters__item"
            :class="{ 'filters__item--dd': breakPoint == 'desktop' || breakPoint == 'desktop-l' }"
          >
            <div
              class="filters__select"
              :class="{ active: activeFilter == 'moq', selected: moqFilters.counter > 0 }"
              @click.stop="setActiveFilter('moq')"
            >
              <span class="filters__label" :class="{ hidden: moqFilters.counter }"> Minimum order quantity </span>
              <span v-if="moqFilters.counter" class="filters__selected"> Minimum order quantity </span>
              <span v-if="moqFilters.counter" class="filters__counter"> 1 </span>
            </div>
            <div class="filters__box" :class="{ active: activeFilter == 'moq' }" @click.stop>
              <span class="filters__box-label">
                {{ moq == 2000 ? `2000+` : moq || 1 }}
              </span>
              <vue-slider
                class="supplier__range-slider"
                :value="+moq || 1"
                :data="[1, 20, 50, 100, 150, 200, 300, 500, 800, 1000, 1500, 2000]"
                :min="1"
                :max="2000"
                :width="'100%'"
                :tooltip="false"
                :bgStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.13)' }"
                :sliderStyle="{ backgroundColor: '#fff', border: '2px solid #37495e' }"
                :processStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.8)' }"
                ref="moqSlider"
                @callback="moqChange"
              >
              </vue-slider>
              <div v-if="breakPoint != 'mobile' && breakPoint != 'tablet'" class="filters__controls">
                <a
                  v-if="moqFiltersChanged || !moqFilters.query"
                  href="#"
                  class="filters__cancel"
                  @click.prevent="cancelFilters()"
                  >Cancel</a
                >
                <a v-else href="#" class="filters__clear" @click.prevent="clearFilters('moq')">Clear</a>
                <a
                  href="#"
                  class="filters__apply"
                  @click.prevent="
                    applyFilters();
                    activeFilter = '';
                  "
                  >Apply</a
                >
              </div>
            </div>
          </div>
          <div
            class="filters__item"
            :class="{ 'filters__item--dd': breakPoint == 'desktop' || breakPoint == 'desktop-l' }"
          >
            <div
              class="filters__select"
              :class="{ active: activeFilter == 'niche', selected: nicheFilters.counter > 0 }"
              @click.stop="setActiveFilter('niche')"
            >
              <span class="filters__label" :class="{ hidden: nicheFilters.counter }"> Niche </span>
              <span v-if="nicheFilters.counter" class="filters__selected">
                {{ nicheFilters.label }}
              </span>
              <span v-if="nicheFilters.counter > 1" class="filters__counter">
                {{ nicheFilters.counter }}
              </span>
            </div>
            <div class="filters__box" :class="{ active: activeFilter == 'niche' }" @click.stop>
              <ul class="filters__box-list" ref="nicheList">
                <li class="filters__box-item" v-for="(item, index) in filters.niche" :key="index">
                  <label class="filters__option">
                    <input type="checkbox" class="filters__option-checkbox" v-model="item.checked" />
                    <span class="filters__option-name">{{ item.title }}</span>
                  </label>
                </li>
              </ul>
              <div v-if="breakPoint != 'mobile' && breakPoint != 'tablet'" class="filters__controls">
                <a
                  v-if="nicheFiltersChanged || !nicheFilters.query"
                  href="#"
                  class="filters__cancel"
                  @click.prevent="cancelFilters()"
                  >Cancel</a
                >
                <a v-else href="#" class="filters__clear" @click.prevent="clearFilters('niche')">Clear</a>
                <a
                  href="#"
                  class="filters__apply"
                  @click.prevent="
                    applyFilters();
                    activeFilter = '';
                  "
                  >Apply</a
                >
              </div>
            </div>
          </div>
          <div
            class="filters__item"
            :class="{ 'filters__item--dd': breakPoint == 'desktop' || breakPoint == 'desktop-l' }"
          >
            <div
              class="filters__select"
              :class="{ active: activeFilter == 'processes', selected: processesFilters.counter > 0 }"
              @click.stop="setActiveFilter('processes')"
            >
              <span class="filters__label" :class="{ hidden: processesFilters.counter }"> Process </span>
              <span v-if="processesFilters.counter" class="filters__selected">
                {{ processesFilters.label }}
              </span>
              <span v-if="processesFilters.counter > 1" class="filters__counter">
                {{ processesFilters.counter }}
              </span>
            </div>
            <div class="filters__box" :class="{ active: activeFilter == 'processes' }" @click.stop>
              <ul class="filters__box-list" ref="processesList">
                <li class="filters__box-item" v-for="(item, index) in filters.processes" :key="index">
                  <label class="filters__option">
                    <input type="checkbox" class="filters__option-checkbox" v-model="item.checked" />
                    <span class="filters__option-name">{{ item.title }}</span>
                  </label>
                </li>
              </ul>
              <div v-if="breakPoint != 'mobile' && breakPoint != 'tablet'" class="filters__controls">
                <a
                  v-if="processesFiltersChanged || !processesFilters.query"
                  href="#"
                  class="filters__cancel"
                  @click.prevent="cancelFilters()"
                  >Cancel</a
                >
                <a v-else href="#" class="filters__clear" @click.prevent="clearFilters('processes')">Clear</a>
                <a
                  href="#"
                  class="filters__apply"
                  @click.prevent="
                    applyFilters();
                    activeFilter = '';
                  "
                  >Apply</a
                >
              </div>
            </div>
          </div>

          <div
            class="filters__item"
            :class="{ 'filters__item--dd': breakPoint == 'desktop' || breakPoint == 'desktop-l' }"
          >
            <div
              class="filters__select"
              :class="{ active: activeFilter == 'capabilities', selected: capabilitiesFilters.counter > 0 }"
              @click.stop="setActiveFilter('capabilities')"
            >
              <span class="filters__label" :class="{ hidden: capabilitiesFilters.counter }"> Capabilities </span>
              <span v-if="capabilitiesFilters.counter" class="filters__selected">
                {{ capabilitiesFilters.label }}
              </span>
              <span v-if="capabilitiesFilters.counter > 1" class="filters__counter">
                {{ capabilitiesFilters.counter }}
              </span>
            </div>
            <div class="filters__box" :class="{ active: activeFilter == 'capabilities' }" @click.stop>
              <div class="filters__box-list" ref="capabilitiesList">
                <div
                  v-if="i == 0 || item.category != filters.capabilities[i - 1].category"
                  class="filters__box-list-group"
                  v-for="(item, i) in filters.capabilities"
                  :key="i"
                >
                  <div class="filters__box-list-label">{{ item.category }}</div>
                  <div
                    class="filters__box-item"
                    v-for="(item, index) in filters.capabilities.filter((i) => i.category == item.category)"
                    :key="index"
                  >
                    <label class="filters__option filters__option">
                      <input type="checkbox" class="filters__option-checkbox" v-model="item.checked" />
                      <span class="filters__option-name">{{ item.title }}</span>
                    </label>
                  </div>
                </div>
              </div>
              <div v-if="breakPoint != 'mobile' && breakPoint != 'tablet'" class="filters__controls">
                <a
                  v-if="capabilitiesFiltersChanged || !capabilitiesFilters.query"
                  href="#"
                  class="filters__cancel"
                  @click.prevent="cancelFilters()"
                  >Cancel</a
                >
                <a v-else href="#" class="filters__clear" @click.prevent="clearFilters('capabilities')">Clear</a>
                <a
                  href="#"
                  class="filters__apply"
                  @click.prevent="
                    applyFilters();
                    activeFilter = '';
                  "
                  >Apply</a
                >
              </div>
            </div>
          </div>

          <div
            class="filters__group"
            :class="{
              'filters__group--visible': breakPoint == 'desktop-l',
              'filters__group--container': breakPoint == 'desktop',
              active: activeFilter == 'moreFilters' && breakPoint == 'desktop',
            }"
            :ref="breakPoint == 'desktop' ? 'moreFiltersList' : ''"
            @click="
              (e) => {
                if (activeFilter == 'moreFilters' && breakPoint == 'desktop') e.stopPropagation();
              }
            "
          >
            <div class="filters__group-items" @click.stop>
              <div class="filters__item" :class="{ 'filters__item--dd': breakPoint == 'desktop-l' }">
                <div
                  class="filters__select"
                  :class="{ active: activeFilter == 'lastingMethods', selected: lastingMethodsFilters.counter > 0 }"
                  @click.stop="setActiveFilter('lastingMethods')"
                >
                  <span class="filters__label" :class="{ hidden: lastingMethodsFilters.counter }">
                    Lasting Method
                  </span>
                  <span v-if="lastingMethodsFilters.counter" class="filters__selected">
                    {{ lastingMethodsFilters.label }}
                  </span>
                  <span v-if="lastingMethodsFilters.counter > 1" class="filters__counter">
                    {{ lastingMethodsFilters.counter }}
                  </span>
                </div>
                <div class="filters__box" :class="{ active: activeFilter == 'lastingMethods' }" @click.stop>
                  <ul class="filters__box-list" ref="lastingMethodsList">
                    <li class="filters__box-item" v-for="(item, index) in filters.lastingMethods" :key="index">
                      <label class="filters__option">
                        <input type="checkbox" class="filters__option-checkbox" v-model="item.checked" />
                        <span class="filters__option-name">{{ item.title }}</span>
                      </label>
                    </li>
                  </ul>
                  <div
                    v-if="breakPoint != 'mobile' && breakPoint != 'tablet' && breakPoint != 'desktop'"
                    class="filters__controls"
                  >
                    <a
                      v-if="lastingMethodsFiltersChanged || !lastingMethodsFilters.query"
                      href="#"
                      class="filters__cancel"
                      @click.prevent="cancelFilters()"
                      >Cancel</a
                    >
                    <a v-else href="#" class="filters__clear" @click.prevent="clearFilters('lastingMethods')">Clear</a>
                    <a
                      href="#"
                      class="filters__apply"
                      @click.prevent="
                        applyFilters();
                        activeFilter = '';
                      "
                      >Apply</a
                    >
                  </div>
                </div>
              </div>

              <div class="filters__item" :class="{ 'filters__item--dd': breakPoint == 'desktop-l' }">
                <div
                  class="filters__select"
                  :class="{ active: activeFilter == 'dailyProduction', selected: dailyProductionFilters.counter > 0 }"
                  @click.stop="setActiveFilter('dailyProduction')"
                >
                  <span class="filters__label" :class="{ hidden: dailyProductionFilters.counter }">
                    Daily Production (Pairs)
                  </span>
                  <span v-if="dailyProductionFilters.counter" class="filters__selected">
                    Daily Production (Pairs)
                  </span>
                  <span v-if="dailyProductionFilters.counter" class="filters__counter"> 1 </span>
                </div>
                <div class="filters__box" :class="{ active: activeFilter == 'dailyProduction' }" @click.stop>
                  <span class="filters__box-label">
                    {{ dailyProductionFrom || 1 }} - {{ dailyProductionTo || 2000 }}
                  </span>
                  <vue-slider
                    class="supplier__range-slider"
                    :value="[+dailyProductionFrom || 1, +dailyProductionTo || 2000]"
                    :data="[
                      1, 20, 50, 100, 150, 200, 250, 300, 350, 400, 450, 500, 550, 600, 650, 700, 800, 900, 1000, 1100,
                      1200, 1500, 2000,
                    ]"
                    :min="1"
                    :max="2000"
                    :width="'100%'"
                    :tooltip="false"
                    :bgStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.13)' }"
                    :sliderStyle="{ backgroundColor: '#fff', border: '2px solid #37495e' }"
                    :processStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.8)' }"
                    ref="dailyProductionSlider"
                    @callback="dailyProductionChange"
                  >
                  </vue-slider>
                  <div
                    v-if="breakPoint != 'mobile' && breakPoint != 'tablet' && breakPoint != 'desktop'"
                    class="filters__controls"
                  >
                    <a
                      v-if="dailyProductionFiltersChanged || !dailyProductionFilters.query"
                      href="#"
                      class="filters__cancel"
                      @click.prevent="cancelFilters()"
                      >Cancel</a
                    >
                    <a v-else href="#" class="filters__clear" @click.prevent="clearFilters('dailyProduction')">Clear</a>
                    <a
                      href="#"
                      class="filters__apply"
                      @click.prevent="
                        applyFilters();
                        activeFilter = '';
                      "
                      >Apply</a
                    >
                  </div>
                </div>
              </div>

              <div class="filters__item" :class="{ 'filters__item--dd': breakPoint == 'desktop-l' }">
                <div
                  class="filters__select"
                  :class="{ active: activeFilter == 'sizeRange', selected: sizeRangeFilter.counter > 0 }"
                  @click.stop="setActiveFilter('sizeRange')"
                >
                  <span class="filters__label" :class="{ hidden: sizeRangeFilter.counter }"> Size Range </span>
                  <span v-if="sizeRangeFilter.counter" class="filters__selected"> Size Range </span>
                  <span v-if="sizeRangeFilter.counter" class="filters__counter"> 1 </span>
                </div>
                <div class="filters__box" :class="{ active: activeFilter == 'sizeRange' }" @click.stop>
                  <span class="filters__box-label"> {{ sizeRangeFrom || 11 }} - {{ sizeRangeTo || 55 }} </span>
                  <vue-slider
                    class="supplier__range-slider"
                    :value="[sizeRangeFrom || 11, sizeRangeTo || 55]"
                    :min="11"
                    :max="55"
                    :width="'100%'"
                    :tooltip="false"
                    :bgStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.13)' }"
                    :sliderStyle="{ backgroundColor: '#fff', border: '2px solid #37495e' }"
                    :processStyle="{ backgroundColor: 'rgba(55, 73, 94, 0.8)' }"
                    ref="sizeRangeSlider"
                    @callback="sizeRangeChange"
                  >
                  </vue-slider>
                  <div
                    v-if="breakPoint != 'mobile' && breakPoint != 'tablet' && breakPoint != 'desktop'"
                    class="filters__controls"
                  >
                    <a
                      v-if="sizeRangeFilterChanged || !sizeRangeFilter.query"
                      href="#"
                      class="filters__cancel"
                      @click.prevent="cancelFilters()"
                      >Cancel</a
                    >
                    <a v-else href="#" class="filters__clear" @click.prevent="clearFilters('sizeRange')">Clear</a>
                    <a
                      href="#"
                      class="filters__apply"
                      @click.prevent="
                        applyFilters();
                        activeFilter = '';
                      "
                      >Apply</a
                    >
                  </div>
                </div>
              </div>

              <div class="filters__item" :class="{ 'filters__item--dd': breakPoint == 'desktop-l' }">
                <div
                  class="filters__select"
                  :class="{ active: activeFilter == 'associations', selected: associationsFilters.counter > 0 }"
                  @click.stop="setActiveFilter('associations')"
                >
                  <span class="filters__label" :class="{ hidden: associationsFilters.counter }"> Associations </span>
                  <span v-if="associationsFilters.counter" class="filters__selected">
                    {{ associationsFilters.label }}
                  </span>
                  <span v-if="associationsFilters.counter > 1" class="filters__counter">
                    {{ associationsFilters.counter }}
                  </span>
                </div>
                <div class="filters__box" :class="{ active: activeFilter == 'associations' }" @click.stop>
                  <ul class="filters__box-list" ref="associationsList">
                    <li class="filters__box-item" v-for="item in filters.associations" :key="item.title">
                      <label class="filters__option">
                        <input type="checkbox" class="filters__option-checkbox" v-model="item.checked" />
                        <span class="filters__option-name">{{ item.title }}</span>
                      </label>
                    </li>
                  </ul>
                  <div
                    v-if="breakPoint != 'mobile' && breakPoint != 'tablet' && breakPoint != 'desktop'"
                    class="filters__controls"
                  >
                    <a
                      v-if="associationsFiltersChanged || !associationsFilters.query"
                      href="#"
                      class="filters__cancel"
                      @click.prevent="cancelFilters()"
                      >Cancel</a
                    >
                    <a v-else href="#" class="filters__clear" @click.prevent="clearFilters('associations')">Clear</a>
                    <a
                      href="#"
                      class="filters__apply"
                      @click.prevent="
                        applyFilters();
                        activeFilter = '';
                      "
                      >Apply</a
                    >
                  </div>
                </div>
              </div>

              <div v-if="breakPoint == 'tablet' || breakPoint == 'desktop'" class="filters__group-footer">
                <a
                  v-if="filtersChanged || !appliedFilters"
                  href="#"
                  class="filters__cancel"
                  @click.prevent="
                    cancelFilters();
                    activeFilter = '';
                  "
                >
                  Cancel
                </a>
                <a v-else href="#" class="filters__cancel" @click.prevent="clearMoreFilters()"> Clear </a>
                <a
                  href="#"
                  class="filters__apply"
                  @click.prevent="
                    applyFilters();
                    activeFilter = '';
                  "
                >
                  Apply
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="breakPoint != 'mobile' && breakPoint != 'desktop-l'"
        class="filters__item filters__item--dd"
        @click.stop="setActiveFilter('moreFilters')"
      >
        <div
          class="filters__select"
          :class="{ active: activeFilter == 'moreFilters', selected: moreFiltersCounter > 0 }"
        >
          <span class="filters__label"> More Filters </span>
          <span v-if="moreFiltersCounter" class="filters__counter">
            {{ moreFiltersCounter }}
          </span>
        </div>
      </div>

      <div v-if="breakPoint == 'mobile'" class="filters__mobile-controls">
        <a
          v-if="filtersChanged || !appliedFilters"
          href="#"
          class="filters__cancel"
          @click.prevent="
            cancelFilters();
            $emit('update:activeFilters', false);
          "
        >
          Cancel
        </a>
        <a
          v-else
          href="#"
          class="filters__cancel"
          @click.prevent="
            $router.push({ name: $route.name, query: null });
            $emit('update:activeFilters', false);
          "
        >
          Clear
        </a>
        <a
          href="#"
          class="filters__apply"
          @click.prevent="
            applyFilters();
            $emit('update:activeFilters', false);
          "
        >
          Apply
        </a>
      </div>
    </div>
    <button
      class="filters__toggle"
      :class="{ active: activeFilters, selected: countAllFilters }"
      @click="
        applyFilters();
        $emit('update:activeFilters', !activeFilters);
      "
    >
      <span class="filters__toggle-label">Filters</span>
      <span v-if="countAllFilters" class="filters__counter">
        {{ countAllFilters }}
      </span>
    </button>
  </div>
</template>

<script>
import VueSlider from 'vue-slider-component/src/vue2-slider.vue';

export default {
  components: { VueSlider },
  props: {
    activeFilters: {
      default: false,
    },
  },
  data() {
    return {
      activeFilter: '',
      breakPoint: 'mobile',
      supplierName: null,
      filters: {
        footwearTypes: [],
        location: [],
        processes: [],
        gender: [
          {
            title: 'Men',
            key: 'men',
            checked: false,
          },
          {
            title: 'Ladies',
            key: 'ladies',
            checked: false,
          },
          {
            title: 'Boys & Girls',
            key: 'kids',
            checked: false,
          },
          {
            title: 'Babies',
            key: 'babies',
            checked: false,
          },
        ],
        lastingMethods: [],
        niche: [],
        capabilities: [],
        associations: [
          { title: 'APICCAPS (Portugal)', key: 'apiccaps', checked: false },
          { title: 'SATRA', key: 'satra', checked: false },
          { title: 'CICEG (Mexico)', key: 'ciceg', checked: false },
        ],
      },
      moq: null,
      dailyProductionFrom: null,
      dailyProductionTo: null,
      sizeRangeFrom: null,
      sizeRangeTo: null,
      appliedFilters: {},
    };
  },
  computed: {
    supplierNameFilters() {
      return this.checkActiveFilters(null, 'supplierName');
    },
    supplierNameFiltersChanged() {
      return this.checkIfFilterChanged('supplierName', this.supplierNameFilters.query);
    },
    footwearTypesFilters() {
      return this.checkActiveFilters(this.filters.footwearTypes, 'footwearTypes');
    },
    footwearTypesFiltersChanged() {
      return this.checkIfFilterChanged('footwearTypes', this.footwearTypesFilters.query);
    },
    genderFilters() {
      return this.checkActiveFilters(this.filters.gender, 'gender');
    },
    genderFiltersChanged() {
      return this.checkIfFilterChanged('gender', this.genderFilters.query);
    },
    locationFilters() {
      return this.checkActiveFilters(this.filters.location, 'location');
    },
    locationFiltersChanged() {
      return this.checkIfFilterChanged('location', this.locationFilters.query);
    },
    moqFilters() {
      return this.checkActiveFilters(null, 'moq');
    },
    moqFiltersChanged() {
      return this.checkIfFilterChanged('moq', this.moqFilters.query);
    },
    nicheFilters() {
      return this.checkActiveFilters(this.filters.niche, 'niche');
    },
    nicheFiltersChanged() {
      return this.checkIfFilterChanged('niche', this.nicheFilters.query);
    },
    processesFilters() {
      return this.checkActiveFilters(this.filters.processes, 'processes');
    },
    processesFiltersChanged() {
      return this.checkIfFilterChanged('processes', this.processesFilters.query);
    },
    capabilitiesFilters() {
      return this.checkActiveFilters(this.filters.capabilities, 'capabilities');
    },
    capabilitiesFiltersChanged() {
      return this.checkIfFilterChanged('capabilities', this.capabilitiesFilters.query);
    },
    lastingMethodsFilters() {
      return this.checkActiveFilters(this.filters.lastingMethods, 'lastingMethods');
    },
    lastingMethodsFiltersChanged() {
      return this.checkIfFilterChanged('lastingMethods', this.lastingMethodsFilters.query);
    },
    dailyProductionFilters() {
      return this.checkActiveFilters(null, 'dailyProduction');
    },
    dailyProductionFiltersChanged() {
      return this.checkIfFilterChanged('dailyProduction', this.dailyProductionFilters.query);
    },
    sizeRangeFilter() {
      return this.checkActiveFilters(null, 'sizeRange');
    },
    sizeRangeFilterChanged() {
      return this.checkIfFilterChanged('sizeRange', this.sizeRangeFilter.query);
    },
    associationsFilters() {
      return this.checkActiveFilters(this.filters.associations, 'associations');
    },
    associationsFiltersChanged() {
      return this.checkIfFilterChanged('associations', this.associationsFilters.query);
    },
    filtersChanged() {
      return (
        this.supplierNameFiltersChanged ||
        this.footwearTypesFiltersChanged ||
        this.genderFiltersChanged ||
        this.locationFiltersChanged ||
        this.moqFiltersChanged ||
        this.nicheFiltersChanged ||
        this.processesFiltersChanged ||
        this.capabilitiesFiltersChanged ||
        this.lastingMethodsFiltersChanged ||
        this.dailyProductionFiltersChanged ||
        this.sizeRangeFilterChanged ||
        this.associationsFiltersChanged
      );
    },
    countAllFilters() {
      return (
        this.supplierNameFilters.counter +
        this.footwearTypesFilters.counter +
        this.genderFilters.counter +
        this.locationFilters.counter +
        this.moqFilters.counter +
        this.nicheFilters.counter +
        this.processesFilters.counter +
        this.capabilitiesFilters.counter +
        this.lastingMethodsFilters.counter +
        this.dailyProductionFilters.counter +
        this.sizeRangeFilter.counter +
        this.associationsFilters.counter
      );
    },
    moreFiltersCounter() {
      if (this.breakPoint == 'tablet') {
        return (
          this.processesFilters.counter +
          this.capabilitiesFilters.counter +
          this.lastingMethodsFilters.counter +
          this.dailyProductionFilters.counter +
          this.sizeRangeFilter.counter +
          this.associationsFilters.counter
        );
      } else {
        return (
          this.lastingMethodsFilters.counter +
          this.dailyProductionFilters.counter +
          this.sizeRangeFilter.counter +
          this.associationsFilters.counter
        );
      }
    },
  },
  methods: {
    setArticlesFilters() {
      // Footwear Types
      let ft = this.$store.categories.all.filter((c) => c.slug === 'footwear-types');
      this.filters.footwearTypes = ft[0].articles.map((a) => {
        return {
          title: a.title,
          checked: false,
        };
      });

      // Processes
      let p = this.$store.categories.all.filter((c) => c.slug === 'construction');
      this.filters.processes = p[0].articles.map((a) => {
        return {
          title: a.title,
          checked: false,
        };
      });
    },
    getFilters() {
      this.setArticlesFilters();
      let f = this.$store.suppliers.filters.footwearSuppliersFilters;
      // Countries
      this.filters.location = f.countries.map((i) => {
        return {
          title: i,
          checked: false,
        };
      });
      // Lasting Methods
      this.filters.lastingMethods = f.lastingMethods.map((i) => {
        return {
          title: i.title,
          checked: false,
        };
      });
      // Niche
      this.filters.niche = f.niche.map((i) => {
        return {
          title: i.title,
          checked: false,
        };
      });
      // Capabilities
      this.filters.capabilities = f.capabilities.map((i) => {
        return {
          title: i.title,
          category: i.category,
          checked: false,
        };
      });

      // Sync filters after all values are fetched
      this.getAppliedFilters();
    },
    setActiveFilter(key = '') {
      if (this.mobile) return;
      if (this.activeFilter == key) {
        this.activeFilter = '';
      } else {
        if (
          this.activeFilter == 'moreFilters' &&
          this.breakPoint == 'tablet' &&
          (key == 'moq' ||
            key == 'niche' ||
            key == 'processes' ||
            key == 'capabilities' ||
            key == 'lastingMethods' ||
            key == 'dailyProduction' ||
            key == 'sizeRange' ||
            key == 'associations')
        ) {
          return;
        }
        if (
          this.activeFilter == 'moreFilters' &&
          this.breakPoint == 'desktop' &&
          (key == 'lastingMethods' || key == 'dailyProduction' || key == 'sizeRange' || key == 'associations')
        ) {
          return;
        }

        this.activeFilter = key;
        if (key && this.$refs[`${key}List`]) this.$refs[`${key}List`].scrollTop = 0;

        // Trigger range slider
        if (key == 'sizeRange' || key == 'moq' || key == 'dailyProduction' || key == 'moreFilters') {
          setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
          }, 50);
        }
      }
    },
    setActiveFilterListener() {
      this.setActiveFilter();
      if (this.breakPoint != 'mobile') this.applyFilters();
    },
    checkWidth() {
      if (window.innerWidth < 768) {
        this.breakPoint = 'mobile';
      } else if (window.innerWidth < 1200) {
        this.breakPoint = 'tablet';
      } else if (window.innerWidth < 1600) {
        this.breakPoint = 'desktop';
      } else {
        this.breakPoint = 'desktop-l';
      }
    },
    getAppliedFilters() {
      this.appliedFilters = Object.keys(this.$route.query).length ? this.$route.query : null;
      this.syncFilters(this.appliedFilters);
    },
    checkActiveFilters(group, name) {
      if (name == 'supplierName') {
        return {
          name,
          counter: this.supplierName ? 1 : 0,
          query: this.supplierName || null,
        };
      }
      // Radio filters
      if (name == 'moq') {
        return {
          name,
          label: this.moq == 2000 ? `2000+` : this.moq,
          counter: this.moq ? 1 : 0,
          query: this.moq || null,
        };
      }
      if (name == 'dailyProduction') {
        return {
          name,
          label: `${this.dailyProductionFrom} - ${this.dailyProductionTo}`,
          counter: this.dailyProductionFrom && this.dailyProductionTo ? 1 : 0,
          query:
            this.dailyProductionFrom && this.dailyProductionTo
              ? `${this.dailyProductionFrom}-${this.dailyProductionTo}`
              : null,
        };
      }
      // Range filters
      if (name == 'sizeRange') {
        return {
          name,
          label: `${this.sizeRangeFrom} - ${this.sizeRangeTo}`,
          counter: this.sizeRangeFrom && this.sizeRangeTo ? 1 : 0,
          query: this.sizeRangeFrom && this.sizeRangeTo ? `${this.sizeRangeFrom}-${this.sizeRangeTo}` : null,
        };
      }

      if (!group) return;
      // Checkbox filters
      let filters = [],
        counter = 0;
      group.forEach((f) => {
        if (f.checked) {
          filters.push(f.title);
          counter++;
        }
      });
      return {
        name,
        label: filters[0],
        counter,
        query: counter && counter > 1 ? filters : filters[0],
      };
    },
    setFilterValue(filter, title) {
      // Unknown query string parameter sent in as filter
      if (!this.filters[filter]) return;

      this.filters[filter].forEach((i) => {
        if (i.title === title) {
          i.checked = true;
        }
      });
    },
    moqChange(value) {
      this.moq = value;
    },
    dailyProductionChange(value) {
      this.dailyProductionFrom = value[0];
      this.dailyProductionTo = value[1];
    },
    sizeRangeChange(value) {
      this.sizeRangeFrom = value[0];
      this.sizeRangeTo = value[1];
    },
    resetFilters() {
      this.supplierName = null;
      this.moq = null;
      this.dailyProductionFrom = null;
      this.dailyProductionTo = null;

      this.sizeRangeFrom = null;
      this.sizeRangeTo = null;

      for (let key in this.filters) {
        this.filters[key].forEach((i) => {
          i.checked = false;
        });
      }
    },
    syncFilters(filters) {
      this.resetFilters();
      if (filters) {
        for (let key in filters) {
          if (key == 'supplierName') {
            this.supplierName = filters['supplierName'];
          } else if (key == 'moq') {
            this.moq = filters['moq'];
          } else if (key == 'dailyProduction') {
            let val = filters['dailyProduction'].split('-');
            this.dailyProductionFrom = val[0];
            this.dailyProductionTo = val[1];
          } else if (key == 'sizeRange') {
            let val = filters['sizeRange'].split('-');
            this.sizeRangeFrom = val[0];
            this.sizeRangeTo = val[1];
          } else if (typeof filters[key] == 'string') {
            this.setFilterValue(key, filters[key]);
          } else {
            filters[key].forEach((i) => {
              this.setFilterValue(key, i);
            });
          }
        }
      }
    },
    cancelFilters() {
      this.getAppliedFilters();
      this.activeFilter = '';
    },
    clearFilter(name) {
      if (name == 'supplierName') this.supplierName = null;
      else if (name == 'moq') this.moq = null;
      else if (name == 'dailyProduction') {
        this.dailyProductionFrom = null;
        this.dailyProductionTo = null;
      } else if (name == 'sizeRange') {
        this.sizeRangeFrom = null;
        this.sizeRangeTo = null;
      } else {
        this.filters[name].forEach((f) => (f.checked = false));
      }
    },
    clearFilters(names) {
      this.activeFilter = '';
      if (typeof names == 'string') {
        this.clearFilter(names);
      } else {
        names.forEach((name) => this.clearFilter(name));
      }
      this.$router.push({ name: this.$route.name, query: this.filtersToAppy() });
    },
    clearMoreFilters() {
      let filtersToClear;
      if (this.breakPoint == 'tablet') {
        filtersToClear = [
          'processes',
          'capabilities',
          'lastingMethods',
          'dailyProduction',
          'sizeRange',
          'associations',
        ];
      }
      if (this.breakPoint == 'desktop') {
        filtersToClear = ['lastingMethods', 'dailyProduction', 'sizeRange', 'associations'];
      }
      this.clearFilters(filtersToClear);
    },
    applyFilters() {
      let query = this.filtersToAppy();

      // TODO: Refactoring
      if (
        JSON.stringify(query) === JSON.stringify(this.appliedFilters) ||
        (!Object.keys(query).length && !this.appliedFilters)
      )
        return;

      this.activeFilter = '';
      this.$router.push({ name: this.$route.name, query: query });
    },
    filtersToAppy() {
      let obj = {};
      [
        this.supplierNameFilters,
        this.footwearTypesFilters,
        this.genderFilters,
        this.locationFilters,
        this.processesFilters,
        this.lastingMethodsFilters,
        this.nicheFilters,
        this.moqFilters,
        this.dailyProductionFilters,
        this.capabilitiesFilters,
        this.sizeRangeFilter,
        this.associationsFilters,
      ].forEach((filter) => {
        if (filter.counter) {
          obj[filter.name] = filter.query;
        }
      });
      return obj;
    },
    checkIfFilterChanged(slug, selected) {
      let applied = this.appliedFilters;

      // False if both values are not defined
      if ((!applied || !applied[slug]) && !selected) return false;

      // True if one value is defind and other is not.
      // True if values have different data types
      if ((!applied && selected) || typeof applied[slug] !== typeof selected) return true;

      // Compare values (string, number / array)
      if (typeof selected === 'string' || typeof selected === 'number') {
        return applied[slug] !== selected;
      } else {
        let check =
          applied[slug].length == selected.length &&
          applied[slug].every((e, i) => {
            return e === selected[i];
          });
        return !check;
      }
    },
    changeCategory() {
      this.$router.push({ name: 'componentSuppliers' });
    },
  },
  watch: {
    '$route.query'() {
      this.getAppliedFilters();
    },
    activeFilters(value) {
      if (value) {
        setTimeout(() => {
          // Trigger range slider
          window.dispatchEvent(new Event('resize'));
          // Scroll to top
          this.$refs['filtersFullList'].scrollTop = 0;
        }, 50);
      }
    },
  },
  mounted() {
    this.checkWidth();
    this.getFilters();
    document.body.addEventListener('click', this.setActiveFilterListener);
    window.addEventListener('resize', this.checkWidth);
  },
  beforeDestroy() {
    document.body.removeEventListener('click', this.setActiveFilterListener);
    window.removeEventListener('resize', this.checkWidth);
  },
};
</script>
