var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filters" }, [
    _c(
      "div",
      {
        ref: "filtersFullList",
        staticClass: "filters__list",
        class: { active: _vm.activeFilters },
      },
      [
        _c("div", { staticClass: "filters__item" }, [
          _c(
            "form",
            {
              attrs: { action: "#" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.applyFilters($event)
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.supplierName,
                    expression: "supplierName",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "filters__input",
                attrs: { type: "text", placeholder: "Filter by name" },
                domProps: { value: _vm.supplierName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.supplierName = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.$store.user.loggedIn
          ? _c(
              "div",
              {
                staticClass: "filters__item",
                class: {
                  "filters__item--dd":
                    _vm.breakPoint == "tablet" ||
                    _vm.breakPoint == "desktop" ||
                    _vm.breakPoint == "desktop-l",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "filters__select selected",
                    class: { active: _vm.activeFilter == "supplierCategory" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.setActiveFilter("supplierCategory")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "filters__selected" }, [
                      _vm._v(" Component "),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "filters__box",
                    class: { active: _vm.activeFilter == "supplierCategory" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c("ul", { staticClass: "filters__box-list" }, [
                      _c("li", { staticClass: "filters__box-item" }, [
                        _c(
                          "label",
                          {
                            staticClass: "filters__option",
                            on: {
                              click: function ($event) {
                                return _vm.changeCategory()
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "filters__option-name filters__option-name--no-checkbox",
                              },
                              [_vm._v("Footwear")]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "filters__box-item" }, [
                        _c(
                          "label",
                          {
                            staticClass: "filters__option",
                            on: {
                              click: function ($event) {
                                return _vm.cancelFilters()
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "filters__option-name filters__option-name--no-checkbox",
                              },
                              [_vm._v("Component")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.breakPoint != "mobile"
                      ? _c("div", { staticClass: "filters__controls" }, [
                          _c(
                            "a",
                            {
                              staticClass: "filters__cancel",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.cancelFilters()
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "filters__item",
            class: {
              "filters__item--dd":
                _vm.breakPoint == "tablet" ||
                _vm.breakPoint == "desktop" ||
                _vm.breakPoint == "desktop-l",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "filters__select",
                class: {
                  active: _vm.activeFilter == "location",
                  selected: _vm.locationFilters.counter > 0,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.setActiveFilter("location")
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "filters__label",
                    class: { hidden: _vm.locationFilters.counter },
                  },
                  [_vm._v(" Location ")]
                ),
                _vm._v(" "),
                _vm.locationFilters.counter
                  ? _c("span", { staticClass: "filters__selected" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.locationFilters.label) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.locationFilters.counter > 1
                  ? _c("span", { staticClass: "filters__counter" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.locationFilters.counter) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "filters__box",
                class: { active: _vm.activeFilter == "location" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "ul",
                  { ref: "locationList", staticClass: "filters__box-list" },
                  _vm._l(_vm.filters.location, function (item, index) {
                    return _c(
                      "li",
                      { key: index, staticClass: "filters__box-item" },
                      [
                        _c("label", { staticClass: "filters__option" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.checked,
                                expression: "item.checked",
                              },
                            ],
                            staticClass: "filters__option-checkbox",
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(item.checked)
                                ? _vm._i(item.checked, null) > -1
                                : item.checked,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = item.checked,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        item,
                                        "checked",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        item,
                                        "checked",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(item, "checked", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "filters__option-name" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.breakPoint != "mobile"
                  ? _c("div", { staticClass: "filters__controls" }, [
                      _vm.locationFiltersChanged || !_vm.locationFilters.query
                        ? _c(
                            "a",
                            {
                              staticClass: "filters__cancel",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.cancelFilters()
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "filters__clear",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.clearFilters("location")
                                },
                              },
                            },
                            [_vm._v("Clear")]
                          ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "filters__apply",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.applyFilters()
                              _vm.activeFilter = ""
                            },
                          },
                        },
                        [_vm._v("Apply")]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm._l(
          _vm.$store.suppliers.typeOfProduction.slice(0, 1),
          function (filter, i) {
            return _c(
              "div",
              {
                key: i,
                staticClass: "filters__item",
                class: {
                  "filters__item--dd":
                    _vm.breakPoint == "tablet" ||
                    _vm.breakPoint == "desktop" ||
                    _vm.breakPoint == "desktop-l",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "filters__select",
                    class: {
                      active: _vm.activeFilter == filter.key,
                      selected: _vm.materialFilters[filter.key].counter > 0,
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.setActiveFilter(filter.key)
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "filters__label",
                        class: {
                          hidden: _vm.materialFilters[filter.key].counter,
                        },
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(filter.title) + "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.materialFilters[filter.key].counter
                      ? _c("span", { staticClass: "filters__selected" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.materialFilters[filter.key].label) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.materialFilters[filter.key].counter > 1
                      ? _c("span", { staticClass: "filters__counter" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.materialFilters[filter.key].counter) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "filters__box",
                    class: { active: _vm.activeFilter == filter.key },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "filters__box-list" },
                      [
                        _vm._l(_vm.filters[filter.key], function (group, i) {
                          return !group.group
                            ? _c(
                                "div",
                                {
                                  key: i,
                                  staticClass:
                                    "filters__box-list-group-wrapper",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "filters__box-item" },
                                    [
                                      _c(
                                        "label",
                                        {
                                          staticClass:
                                            "filters__option filters__option",
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: group.checked,
                                                expression: "group.checked",
                                              },
                                            ],
                                            staticClass:
                                              "filters__option-checkbox",
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                group.checked
                                              )
                                                ? _vm._i(group.checked, null) >
                                                  -1
                                                : group.checked,
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a = group.checked,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          group,
                                                          "checked",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          group,
                                                          "checked",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      group,
                                                      "checked",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                function ($event) {
                                                  return _vm.resetUnderlyingFilters(
                                                    {
                                                      filterKey: filter.key,
                                                      groupKey: group.key,
                                                      checked: group.checked,
                                                    }
                                                  )
                                                },
                                              ],
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "filters__option-name",
                                            },
                                            [_vm._v(_vm._s(group.title))]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.filters[filter.key],
                                        function (item, i) {
                                          return item.group == group.key &&
                                            item.first
                                            ? _c(
                                                "div",
                                                {
                                                  key: item.title,
                                                  staticClass:
                                                    "filters__box-list-group",
                                                  style: group.checked
                                                    ? {}
                                                    : { display: "none" },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "filters__box-list-label",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.category)
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    _vm.filters[
                                                      filter.key
                                                    ].filter(function (i) {
                                                      return (
                                                        i.category ==
                                                          item.category &&
                                                        i.group == group.key
                                                      )
                                                    }),
                                                    function (item) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          key: item.title,
                                                          staticClass:
                                                            "filters__box-item",
                                                        },
                                                        [
                                                          _c(
                                                            "label",
                                                            {
                                                              staticClass:
                                                                "filters__option filters__option",
                                                            },
                                                            [
                                                              _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      item.checked,
                                                                    expression:
                                                                      "item.checked",
                                                                  },
                                                                ],
                                                                staticClass:
                                                                  "filters__option-checkbox",
                                                                attrs: {
                                                                  type: "checkbox",
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    Array.isArray(
                                                                      item.checked
                                                                    )
                                                                      ? _vm._i(
                                                                          item.checked,
                                                                          null
                                                                        ) > -1
                                                                      : item.checked,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      var $$a =
                                                                          item.checked,
                                                                        $$el =
                                                                          $event.target,
                                                                        $$c =
                                                                          $$el.checked
                                                                            ? true
                                                                            : false
                                                                      if (
                                                                        Array.isArray(
                                                                          $$a
                                                                        )
                                                                      ) {
                                                                        var $$v =
                                                                            null,
                                                                          $$i =
                                                                            _vm._i(
                                                                              $$a,
                                                                              $$v
                                                                            )
                                                                        if (
                                                                          $$el.checked
                                                                        ) {
                                                                          $$i <
                                                                            0 &&
                                                                            _vm.$set(
                                                                              item,
                                                                              "checked",
                                                                              $$a.concat(
                                                                                [
                                                                                  $$v,
                                                                                ]
                                                                              )
                                                                            )
                                                                        } else {
                                                                          $$i >
                                                                            -1 &&
                                                                            _vm.$set(
                                                                              item,
                                                                              "checked",
                                                                              $$a
                                                                                .slice(
                                                                                  0,
                                                                                  $$i
                                                                                )
                                                                                .concat(
                                                                                  $$a.slice(
                                                                                    $$i +
                                                                                      1
                                                                                  )
                                                                                )
                                                                            )
                                                                        }
                                                                      } else {
                                                                        _vm.$set(
                                                                          item,
                                                                          "checked",
                                                                          $$c
                                                                        )
                                                                      }
                                                                    },
                                                                },
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "filters__option-name",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.title
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              )
                            : _vm._e()
                        }),
                        _vm._v(" "),
                        _vm._l(_vm.filters[filter.key], function (item, i) {
                          return false &&
                            (i == 0 ||
                              item.category !=
                                _vm.filters[filter.key][i - 1].category)
                            ? _c(
                                "div",
                                {
                                  key: item.title,
                                  staticClass: "filters__box-list-group",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "filters__box-list-label" },
                                    [_vm._v(_vm._s(item.category))]
                                  ),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.filters[filter.key].filter(function (
                                      i
                                    ) {
                                      return i.category == item.category
                                    }),
                                    function (item) {
                                      return _c(
                                        "div",
                                        {
                                          key: item.title,
                                          staticClass: "filters__box-item",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "filters__option filters__option",
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.checked,
                                                    expression: "item.checked",
                                                  },
                                                ],
                                                staticClass:
                                                  "filters__option-checkbox",
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    item.checked
                                                  )
                                                    ? _vm._i(
                                                        item.checked,
                                                        null
                                                      ) > -1
                                                    : item.checked,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a = item.checked,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            item,
                                                            "checked",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            item,
                                                            "checked",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        item,
                                                        "checked",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "filters__option-name",
                                                },
                                                [_vm._v(_vm._s(item.title))]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e()
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _vm.breakPoint != "mobile" && _vm.breakPoint != "tablet"
                      ? _c("div", { staticClass: "filters__controls" }, [
                          _vm.materialFiltersChanged[filter.key] ||
                          !_vm.materialFilters[filter.key].query
                            ? _c(
                                "a",
                                {
                                  staticClass: "filters__cancel",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.cancelFilters()
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              )
                            : _c(
                                "a",
                                {
                                  staticClass: "filters__clear",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.clearFilters(filter.key)
                                    },
                                  },
                                },
                                [_vm._v("Clear")]
                              ),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "filters__apply",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.applyFilters()
                                  _vm.activeFilter = ""
                                },
                              },
                            },
                            [_vm._v("Apply")]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            )
          }
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: _vm.breakPoint == "tablet" ? "moreFiltersList" : "",
            staticClass: "filters__group",
            class: {
              "filters__group--visible":
                _vm.breakPoint == "desktop" || _vm.breakPoint == "desktop-l",
              "filters__group--container": _vm.breakPoint == "tablet",
              active:
                _vm.activeFilter == "moreFilters" && _vm.breakPoint == "tablet",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "filters__group-items",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _vm._l(
                  _vm.$store.suppliers.typeOfProduction.slice(1),
                  function (filter, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "filters__item",
                        class: {
                          "filters__item--dd":
                            _vm.breakPoint == "desktop" ||
                            _vm.breakPoint == "desktop-l",
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "filters__select",
                            class: {
                              active: _vm.activeFilter == filter.key,
                              selected:
                                _vm.materialFilters[filter.key].counter > 0,
                            },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                                return _vm.setActiveFilter(filter.key)
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "filters__label",
                                class: {
                                  hidden:
                                    _vm.materialFilters[filter.key].counter,
                                },
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(filter.title) +
                                    "\n            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.materialFilters[filter.key].counter
                              ? _c(
                                  "span",
                                  { staticClass: "filters__selected" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.materialFilters[filter.key].label
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.materialFilters[filter.key].counter > 1
                              ? _c(
                                  "span",
                                  { staticClass: "filters__counter" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.materialFilters[filter.key]
                                            .counter
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "filters__box",
                            class: { active: _vm.activeFilter == filter.key },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "filters__box-list" },
                              [
                                _vm._l(
                                  _vm.filters[filter.key],
                                  function (group, i) {
                                    return !group.group
                                      ? _c(
                                          "div",
                                          {
                                            key: i,
                                            staticClass:
                                              "filters__box-list-group-wrapper",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "filters__box-item",
                                              },
                                              [
                                                _c(
                                                  "label",
                                                  {
                                                    staticClass:
                                                      "filters__option filters__option",
                                                  },
                                                  [
                                                    _c("input", {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value: group.checked,
                                                          expression:
                                                            "group.checked",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "filters__option-checkbox",
                                                      attrs: {
                                                        type: "checkbox",
                                                      },
                                                      domProps: {
                                                        checked: Array.isArray(
                                                          group.checked
                                                        )
                                                          ? _vm._i(
                                                              group.checked,
                                                              null
                                                            ) > -1
                                                          : group.checked,
                                                      },
                                                      on: {
                                                        change: [
                                                          function ($event) {
                                                            var $$a =
                                                                group.checked,
                                                              $$el =
                                                                $event.target,
                                                              $$c = $$el.checked
                                                                ? true
                                                                : false
                                                            if (
                                                              Array.isArray($$a)
                                                            ) {
                                                              var $$v = null,
                                                                $$i = _vm._i(
                                                                  $$a,
                                                                  $$v
                                                                )
                                                              if (
                                                                $$el.checked
                                                              ) {
                                                                $$i < 0 &&
                                                                  _vm.$set(
                                                                    group,
                                                                    "checked",
                                                                    $$a.concat([
                                                                      $$v,
                                                                    ])
                                                                  )
                                                              } else {
                                                                $$i > -1 &&
                                                                  _vm.$set(
                                                                    group,
                                                                    "checked",
                                                                    $$a
                                                                      .slice(
                                                                        0,
                                                                        $$i
                                                                      )
                                                                      .concat(
                                                                        $$a.slice(
                                                                          $$i +
                                                                            1
                                                                        )
                                                                      )
                                                                  )
                                                              }
                                                            } else {
                                                              _vm.$set(
                                                                group,
                                                                "checked",
                                                                $$c
                                                              )
                                                            }
                                                          },
                                                          function ($event) {
                                                            return _vm.resetUnderlyingFilters(
                                                              {
                                                                filterKey:
                                                                  filter.key,
                                                                groupKey:
                                                                  group.key,
                                                                checked:
                                                                  group.checked,
                                                              }
                                                            )
                                                          },
                                                        ],
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "filters__option-name",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(group.title)
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _vm._l(
                                                  _vm.filters[filter.key],
                                                  function (item, i) {
                                                    return item.group ==
                                                      group.key && item.first
                                                      ? _c(
                                                          "div",
                                                          {
                                                            key: i,
                                                            staticClass:
                                                              "filters__box-list-group",
                                                            style: group.checked
                                                              ? {}
                                                              : {
                                                                  display:
                                                                    "none",
                                                                },
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "filters__box-list-label",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.category
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(" "),
                                                            _vm._l(
                                                              _vm.filters[
                                                                filter.key
                                                              ].filter(
                                                                function (i) {
                                                                  return (
                                                                    i.category ==
                                                                      item.category &&
                                                                    i.group ==
                                                                      group.key
                                                                  )
                                                                }
                                                              ),
                                                              function (item) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: item.title,
                                                                    staticClass:
                                                                      "filters__box-item",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "label",
                                                                      {
                                                                        staticClass:
                                                                          "filters__option filters__option",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "input",
                                                                          {
                                                                            directives:
                                                                              [
                                                                                {
                                                                                  name: "model",
                                                                                  rawName:
                                                                                    "v-model",
                                                                                  value:
                                                                                    item.checked,
                                                                                  expression:
                                                                                    "item.checked",
                                                                                },
                                                                              ],
                                                                            staticClass:
                                                                              "filters__option-checkbox",
                                                                            attrs:
                                                                              {
                                                                                type: "checkbox",
                                                                              },
                                                                            domProps:
                                                                              {
                                                                                checked:
                                                                                  Array.isArray(
                                                                                    item.checked
                                                                                  )
                                                                                    ? _vm._i(
                                                                                        item.checked,
                                                                                        null
                                                                                      ) >
                                                                                      -1
                                                                                    : item.checked,
                                                                              },
                                                                            on: {
                                                                              change:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  var $$a =
                                                                                      item.checked,
                                                                                    $$el =
                                                                                      $event.target,
                                                                                    $$c =
                                                                                      $$el.checked
                                                                                        ? true
                                                                                        : false
                                                                                  if (
                                                                                    Array.isArray(
                                                                                      $$a
                                                                                    )
                                                                                  ) {
                                                                                    var $$v =
                                                                                        null,
                                                                                      $$i =
                                                                                        _vm._i(
                                                                                          $$a,
                                                                                          $$v
                                                                                        )
                                                                                    if (
                                                                                      $$el.checked
                                                                                    ) {
                                                                                      $$i <
                                                                                        0 &&
                                                                                        _vm.$set(
                                                                                          item,
                                                                                          "checked",
                                                                                          $$a.concat(
                                                                                            [
                                                                                              $$v,
                                                                                            ]
                                                                                          )
                                                                                        )
                                                                                    } else {
                                                                                      $$i >
                                                                                        -1 &&
                                                                                        _vm.$set(
                                                                                          item,
                                                                                          "checked",
                                                                                          $$a
                                                                                            .slice(
                                                                                              0,
                                                                                              $$i
                                                                                            )
                                                                                            .concat(
                                                                                              $$a.slice(
                                                                                                $$i +
                                                                                                  1
                                                                                              )
                                                                                            )
                                                                                        )
                                                                                    }
                                                                                  } else {
                                                                                    _vm.$set(
                                                                                      item,
                                                                                      "checked",
                                                                                      $$c
                                                                                    )
                                                                                  }
                                                                                },
                                                                            },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "filters__option-name",
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                item.title
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                          ],
                                                          2
                                                        )
                                                      : _vm._e()
                                                  }
                                                ),
                                              ],
                                              2
                                            ),
                                          ]
                                        )
                                      : _vm._e()
                                  }
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.filters[filter.key],
                                  function (item, i) {
                                    return false &&
                                      (i == 0 ||
                                        item.category !=
                                          _vm.filters[filter.key][i - 1]
                                            .category)
                                      ? _c(
                                          "div",
                                          {
                                            key: i,
                                            staticClass:
                                              "filters__box-list-group",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "filters__box-list-label",
                                              },
                                              [_vm._v(_vm._s(item.category))]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.filters[filter.key].filter(
                                                function (i) {
                                                  return (
                                                    i.category == item.category
                                                  )
                                                }
                                              ),
                                              function (item, i) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: i,
                                                    staticClass:
                                                      "filters__box-item",
                                                  },
                                                  [
                                                    _c(
                                                      "label",
                                                      {
                                                        staticClass:
                                                          "filters__option filters__option",
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                item.checked,
                                                              expression:
                                                                "item.checked",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "filters__option-checkbox",
                                                          attrs: {
                                                            type: "checkbox",
                                                          },
                                                          domProps: {
                                                            checked:
                                                              Array.isArray(
                                                                item.checked
                                                              )
                                                                ? _vm._i(
                                                                    item.checked,
                                                                    null
                                                                  ) > -1
                                                                : item.checked,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  item.checked,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = null,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    _vm.$set(
                                                                      item,
                                                                      "checked",
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      )
                                                                    )
                                                                } else {
                                                                  $$i > -1 &&
                                                                    _vm.$set(
                                                                      item,
                                                                      "checked",
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        )
                                                                    )
                                                                }
                                                              } else {
                                                                _vm.$set(
                                                                  item,
                                                                  "checked",
                                                                  $$c
                                                                )
                                                              }
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "filters__option-name",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.title)
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm.breakPoint != "mobile" &&
                            _vm.breakPoint != "tablet"
                              ? _c(
                                  "div",
                                  { staticClass: "filters__controls" },
                                  [
                                    _vm.materialFiltersChanged[filter.key] ||
                                    !_vm.materialFilters[filter.key].query
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "filters__cancel",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.cancelFilters()
                                              },
                                            },
                                          },
                                          [_vm._v("Cancel")]
                                        )
                                      : _c(
                                          "a",
                                          {
                                            staticClass: "filters__clear",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                return _vm.clearFilters(
                                                  filter.key
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("Clear")]
                                        ),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "filters__apply",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            _vm.applyFilters()
                                            _vm.activeFilter = ""
                                          },
                                        },
                                      },
                                      [_vm._v("Apply")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    )
                  }
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "filters__item",
                    class: {
                      "filters__item--dd":
                        _vm.breakPoint == "desktop" ||
                        _vm.breakPoint == "desktop-l",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "filters__select",
                        class: {
                          active: _vm.activeFilter == "associations",
                          selected: _vm.associationsFilters.counter > 0,
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.setActiveFilter("associations")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "filters__label",
                            class: { hidden: _vm.associationsFilters.counter },
                          },
                          [_vm._v(" Associations ")]
                        ),
                        _vm._v(" "),
                        _vm.associationsFilters.counter
                          ? _c("span", { staticClass: "filters__selected" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.associationsFilters.label) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.associationsFilters.counter > 1
                          ? _c("span", { staticClass: "filters__counter" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.associationsFilters.counter) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "filters__box",
                        class: { active: _vm.activeFilter == "associations" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "ul",
                          {
                            ref: "associationsList",
                            staticClass: "filters__box-list",
                          },
                          _vm._l(_vm.filters.associations, function (item, i) {
                            return _c(
                              "li",
                              { key: i, staticClass: "filters__box-item" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "filters__option" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.checked,
                                          expression: "item.checked",
                                        },
                                      ],
                                      staticClass: "filters__option-checkbox",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(item.checked)
                                          ? _vm._i(item.checked, null) > -1
                                          : item.checked,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = item.checked,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  item,
                                                  "checked",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  item,
                                                  "checked",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(item, "checked", $$c)
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "filters__option-name" },
                                      [_vm._v(_vm._s(item.title))]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.breakPoint != "mobile" && _vm.breakPoint != "tablet"
                          ? _c("div", { staticClass: "filters__controls" }, [
                              _vm.associationsFiltersChanged ||
                              !_vm.associationsFilters.query
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "filters__cancel",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.cancelFilters()
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  )
                                : _c(
                                    "a",
                                    {
                                      staticClass: "filters__clear",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.clearFilters(
                                            "associations"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Clear")]
                                  ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "filters__apply",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.applyFilters()
                                      _vm.activeFilter = ""
                                    },
                                  },
                                },
                                [_vm._v("Apply")]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.breakPoint == "tablet"
                  ? _c("div", { staticClass: "filters__group-footer" }, [
                      _vm.filtersChanged || !_vm.appliedFilters
                        ? _c(
                            "a",
                            {
                              staticClass: "filters__cancel",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  _vm.cancelFilters()
                                  _vm.activeFilter = ""
                                },
                              },
                            },
                            [_vm._v("\n            Cancel\n          ")]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "filters__cancel",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.clearMoreFilters()
                                },
                              },
                            },
                            [_vm._v(" Clear ")]
                          ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "filters__apply",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.applyFilters()
                              _vm.activeFilter = ""
                            },
                          },
                        },
                        [_vm._v("\n            Apply\n          ")]
                      ),
                    ])
                  : _vm._e(),
              ],
              2
            ),
          ]
        ),
        _vm._v(" "),
        _vm.breakPoint != "mobile" &&
        _vm.breakPoint != "desktop" &&
        _vm.breakPoint != "desktop-l"
          ? _c(
              "div",
              {
                staticClass: "filters__item filters__item--dd",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.setActiveFilter("moreFilters")
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "filters__select",
                    class: {
                      active: _vm.activeFilter == "moreFilters",
                      selected: _vm.moreFiltersCounter > 0,
                    },
                  },
                  [
                    _c("span", { staticClass: "filters__label" }, [
                      _vm._v(" More Filters "),
                    ]),
                    _vm._v(" "),
                    _vm.moreFiltersCounter
                      ? _c("span", { staticClass: "filters__counter" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.moreFiltersCounter) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.breakPoint == "mobile"
          ? _c("div", { staticClass: "filters__mobile-controls" }, [
              _vm.filtersChanged || !_vm.appliedFilters
                ? _c(
                    "a",
                    {
                      staticClass: "filters__cancel",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.cancelFilters()
                          _vm.$emit("update:activeFilters", false)
                        },
                      },
                    },
                    [_vm._v("\n        Cancel\n      ")]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "filters__cancel",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.$router.push({
                            name: _vm.$route.name,
                            query: null,
                          })
                          _vm.$emit("update:activeFilters", false)
                        },
                      },
                    },
                    [_vm._v("\n        Clear\n      ")]
                  ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "filters__apply",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.applyFilters()
                      _vm.$emit("update:activeFilters", false)
                    },
                  },
                },
                [_vm._v("\n        Apply\n      ")]
              ),
            ])
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "filters__toggle",
        class: { active: _vm.activeFilters, selected: _vm.countAllFilters },
        on: {
          click: function ($event) {
            _vm.applyFilters()
            _vm.$emit("update:activeFilters", !_vm.activeFilters)
          },
        },
      },
      [
        _c("span", { staticClass: "filters__toggle-label" }, [
          _vm._v("Filters"),
        ]),
        _vm._v(" "),
        _vm.countAllFilters
          ? _c("span", { staticClass: "filters__counter" }, [
              _vm._v("\n      " + _vm._s(_vm.countAllFilters) + "\n    "),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }