var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "filters" }, [
    _c(
      "div",
      {
        ref: "filtersFullList",
        staticClass: "filters__list",
        class: { active: _vm.activeFilters },
      },
      [
        _c("div", { staticClass: "filters__item" }, [
          _c(
            "form",
            {
              attrs: { action: "#" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.applyFilters($event)
                },
              },
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model.trim",
                    value: _vm.supplierName,
                    expression: "supplierName",
                    modifiers: { trim: true },
                  },
                ],
                staticClass: "filters__input",
                attrs: { type: "text", placeholder: "Filter by name" },
                domProps: { value: _vm.supplierName },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.supplierName = $event.target.value.trim()
                  },
                  blur: function ($event) {
                    return _vm.$forceUpdate()
                  },
                },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm.$store.user.loggedIn
          ? _c(
              "div",
              {
                staticClass: "filters__item",
                class: {
                  "filters__item--dd":
                    _vm.breakPoint == "tablet" ||
                    _vm.breakPoint == "desktop" ||
                    _vm.breakPoint == "desktop-l",
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "filters__select selected",
                    class: { active: _vm.activeFilter == "supplierCategory" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.setActiveFilter("supplierCategory")
                      },
                    },
                  },
                  [
                    _c("span", { staticClass: "filters__selected" }, [
                      _vm._v(" Footwear "),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "filters__box",
                    class: { active: _vm.activeFilter == "supplierCategory" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  [
                    _c("ul", { staticClass: "filters__box-list" }, [
                      _c("li", { staticClass: "filters__box-item" }, [
                        _c(
                          "label",
                          {
                            staticClass: "filters__option",
                            on: {
                              click: function ($event) {
                                return _vm.cancelFilters()
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "filters__option-name filters__option-name--no-checkbox",
                              },
                              [_vm._v("Footwear")]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", { staticClass: "filters__box-item" }, [
                        _c(
                          "label",
                          {
                            staticClass: "filters__option",
                            on: {
                              click: function ($event) {
                                return _vm.changeCategory()
                              },
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "filters__option-name filters__option-name--no-checkbox",
                              },
                              [_vm._v("Component")]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.breakPoint != "mobile"
                      ? _c("div", { staticClass: "filters__controls" }, [
                          _c(
                            "a",
                            {
                              staticClass: "filters__cancel",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.cancelFilters()
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "filters__item",
            class: {
              "filters__item--dd":
                _vm.breakPoint == "tablet" ||
                _vm.breakPoint == "desktop" ||
                _vm.breakPoint == "desktop-l",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "filters__select",
                class: {
                  active: _vm.activeFilter == "footwearTypes",
                  selected: _vm.footwearTypesFilters.counter,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.setActiveFilter("footwearTypes")
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "filters__label",
                    class: { hidden: _vm.footwearTypesFilters.counter },
                  },
                  [_vm._v(" Footwear Types ")]
                ),
                _vm._v(" "),
                _vm.footwearTypesFilters.counter
                  ? _c("span", { staticClass: "filters__selected" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.footwearTypesFilters.label) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.footwearTypesFilters.counter > 1
                  ? _c("span", { staticClass: "filters__counter" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.footwearTypesFilters.counter) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "filters__box",
                class: { active: _vm.activeFilter == "footwearTypes" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "ul",
                  {
                    ref: "footwearTypesList",
                    staticClass: "filters__box-list",
                  },
                  _vm._l(_vm.filters.footwearTypes, function (item) {
                    return _c(
                      "li",
                      { key: item, staticClass: "filters__box-item" },
                      [
                        _c("label", { staticClass: "filters__option" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.checked,
                                expression: "item.checked",
                              },
                            ],
                            staticClass: "filters__option-checkbox",
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(item.checked)
                                ? _vm._i(item.checked, null) > -1
                                : item.checked,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = item.checked,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        item,
                                        "checked",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        item,
                                        "checked",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(item, "checked", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "filters__option-name" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.breakPoint != "mobile"
                  ? _c("div", { staticClass: "filters__controls" }, [
                      _vm.footwearTypesFiltersChanged ||
                      !_vm.footwearTypesFilters.query
                        ? _c(
                            "a",
                            {
                              staticClass: "filters__cancel",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.cancelFilters()
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "filters__clear",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.clearFilters("footwearTypes")
                                },
                              },
                            },
                            [_vm._v("Clear")]
                          ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "filters__apply",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.applyFilters()
                              _vm.activeFilter = ""
                            },
                          },
                        },
                        [_vm._v("Apply")]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "filters__item",
            class: {
              "filters__item--dd":
                _vm.breakPoint == "tablet" ||
                _vm.breakPoint == "desktop" ||
                _vm.breakPoint == "desktop-l",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "filters__select",
                class: {
                  active: _vm.activeFilter == "gender",
                  selected: _vm.genderFilters.counter > 0,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.setActiveFilter("gender")
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "filters__label",
                    class: { hidden: _vm.genderFilters.counter },
                  },
                  [_vm._v(" Gender ")]
                ),
                _vm._v(" "),
                _vm.genderFilters.counter
                  ? _c("span", { staticClass: "filters__selected" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.genderFilters.label) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.genderFilters.counter > 1
                  ? _c("span", { staticClass: "filters__counter" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.genderFilters.counter) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "filters__box",
                class: { active: _vm.activeFilter == "gender" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "ul",
                  { ref: "genderList", staticClass: "filters__box-list" },
                  _vm._l(_vm.filters.gender, function (item, index) {
                    return _c(
                      "li",
                      { key: index, staticClass: "filters__box-item" },
                      [
                        _c("label", { staticClass: "filters__option" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.checked,
                                expression: "item.checked",
                              },
                            ],
                            staticClass: "filters__option-checkbox",
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(item.checked)
                                ? _vm._i(item.checked, null) > -1
                                : item.checked,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = item.checked,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        item,
                                        "checked",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        item,
                                        "checked",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(item, "checked", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "filters__option-name" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.breakPoint != "mobile"
                  ? _c("div", { staticClass: "filters__controls" }, [
                      _vm.genderFiltersChanged || !_vm.genderFilters.query
                        ? _c(
                            "a",
                            {
                              staticClass: "filters__cancel",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.cancelFilters()
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "filters__clear",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.clearFilters("gender")
                                },
                              },
                            },
                            [_vm._v("Clear")]
                          ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "filters__apply",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.applyFilters()
                              _vm.activeFilter = ""
                            },
                          },
                        },
                        [_vm._v("Apply")]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "filters__item",
            class: {
              "filters__item--dd":
                _vm.breakPoint == "tablet" ||
                _vm.breakPoint == "desktop" ||
                _vm.breakPoint == "desktop-l",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "filters__select",
                class: {
                  active: _vm.activeFilter == "location",
                  selected: _vm.locationFilters.counter > 0,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.setActiveFilter("location")
                  },
                },
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "filters__label",
                    class: { hidden: _vm.locationFilters.counter },
                  },
                  [_vm._v(" Location ")]
                ),
                _vm._v(" "),
                _vm.locationFilters.counter
                  ? _c("span", { staticClass: "filters__selected" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.locationFilters.label) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.locationFilters.counter > 1
                  ? _c("span", { staticClass: "filters__counter" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.locationFilters.counter) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "filters__box",
                class: { active: _vm.activeFilter == "location" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "ul",
                  { ref: "locationList", staticClass: "filters__box-list" },
                  _vm._l(_vm.filters.location, function (item, index) {
                    return _c(
                      "li",
                      { key: index, staticClass: "filters__box-item" },
                      [
                        _c("label", { staticClass: "filters__option" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: item.checked,
                                expression: "item.checked",
                              },
                            ],
                            staticClass: "filters__option-checkbox",
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(item.checked)
                                ? _vm._i(item.checked, null) > -1
                                : item.checked,
                            },
                            on: {
                              change: function ($event) {
                                var $$a = item.checked,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        item,
                                        "checked",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        item,
                                        "checked",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(item, "checked", $$c)
                                }
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("span", { staticClass: "filters__option-name" }, [
                            _vm._v(_vm._s(item.title)),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.breakPoint != "mobile"
                  ? _c("div", { staticClass: "filters__controls" }, [
                      _vm.locationFiltersChanged || !_vm.locationFilters.query
                        ? _c(
                            "a",
                            {
                              staticClass: "filters__cancel",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.cancelFilters()
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          )
                        : _c(
                            "a",
                            {
                              staticClass: "filters__clear",
                              attrs: { href: "#" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.clearFilters("location")
                                },
                              },
                            },
                            [_vm._v("Clear")]
                          ),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "filters__apply",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.applyFilters()
                              _vm.activeFilter = ""
                            },
                          },
                        },
                        [_vm._v("Apply")]
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: _vm.breakPoint == "tablet" ? "moreFiltersList" : "",
            staticClass: "filters__group",
            class: {
              "filters__group--visible":
                _vm.breakPoint == "desktop" || _vm.breakPoint == "desktop-l",
              "filters__group--container": _vm.breakPoint == "tablet",
              active:
                _vm.activeFilter == "moreFilters" && _vm.breakPoint == "tablet",
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "filters__group-items",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "filters__item",
                    class: {
                      "filters__item--dd":
                        _vm.breakPoint == "desktop" ||
                        _vm.breakPoint == "desktop-l",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "filters__select",
                        class: {
                          active: _vm.activeFilter == "moq",
                          selected: _vm.moqFilters.counter > 0,
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.setActiveFilter("moq")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "filters__label",
                            class: { hidden: _vm.moqFilters.counter },
                          },
                          [_vm._v(" Minimum order quantity ")]
                        ),
                        _vm._v(" "),
                        _vm.moqFilters.counter
                          ? _c("span", { staticClass: "filters__selected" }, [
                              _vm._v(" Minimum order quantity "),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.moqFilters.counter
                          ? _c("span", { staticClass: "filters__counter" }, [
                              _vm._v(" 1 "),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "filters__box",
                        class: { active: _vm.activeFilter == "moq" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "filters__box-label" }, [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.moq == 2000 ? "2000+" : _vm.moq || 1) +
                              "\n            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("vue-slider", {
                          ref: "moqSlider",
                          staticClass: "supplier__range-slider",
                          attrs: {
                            value: +_vm.moq || 1,
                            data: [
                              1, 20, 50, 100, 150, 200, 300, 500, 800, 1000,
                              1500, 2000,
                            ],
                            min: 1,
                            max: 2000,
                            width: "100%",
                            tooltip: false,
                            bgStyle: {
                              backgroundColor: "rgba(55, 73, 94, 0.13)",
                            },
                            sliderStyle: {
                              backgroundColor: "#fff",
                              border: "2px solid #37495e",
                            },
                            processStyle: {
                              backgroundColor: "rgba(55, 73, 94, 0.8)",
                            },
                          },
                          on: { callback: _vm.moqChange },
                        }),
                        _vm._v(" "),
                        _vm.breakPoint != "mobile" && _vm.breakPoint != "tablet"
                          ? _c("div", { staticClass: "filters__controls" }, [
                              _vm.moqFiltersChanged || !_vm.moqFilters.query
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "filters__cancel",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.cancelFilters()
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  )
                                : _c(
                                    "a",
                                    {
                                      staticClass: "filters__clear",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.clearFilters("moq")
                                        },
                                      },
                                    },
                                    [_vm._v("Clear")]
                                  ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "filters__apply",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.applyFilters()
                                      _vm.activeFilter = ""
                                    },
                                  },
                                },
                                [_vm._v("Apply")]
                              ),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "filters__item",
                    class: {
                      "filters__item--dd":
                        _vm.breakPoint == "desktop" ||
                        _vm.breakPoint == "desktop-l",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "filters__select",
                        class: {
                          active: _vm.activeFilter == "niche",
                          selected: _vm.nicheFilters.counter > 0,
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.setActiveFilter("niche")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "filters__label",
                            class: { hidden: _vm.nicheFilters.counter },
                          },
                          [_vm._v(" Niche ")]
                        ),
                        _vm._v(" "),
                        _vm.nicheFilters.counter
                          ? _c("span", { staticClass: "filters__selected" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.nicheFilters.label) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.nicheFilters.counter > 1
                          ? _c("span", { staticClass: "filters__counter" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.nicheFilters.counter) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "filters__box",
                        class: { active: _vm.activeFilter == "niche" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "ul",
                          {
                            ref: "nicheList",
                            staticClass: "filters__box-list",
                          },
                          _vm._l(_vm.filters.niche, function (item, index) {
                            return _c(
                              "li",
                              { key: index, staticClass: "filters__box-item" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "filters__option" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.checked,
                                          expression: "item.checked",
                                        },
                                      ],
                                      staticClass: "filters__option-checkbox",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(item.checked)
                                          ? _vm._i(item.checked, null) > -1
                                          : item.checked,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = item.checked,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  item,
                                                  "checked",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  item,
                                                  "checked",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(item, "checked", $$c)
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "filters__option-name" },
                                      [_vm._v(_vm._s(item.title))]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.breakPoint != "mobile" && _vm.breakPoint != "tablet"
                          ? _c("div", { staticClass: "filters__controls" }, [
                              _vm.nicheFiltersChanged || !_vm.nicheFilters.query
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "filters__cancel",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.cancelFilters()
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  )
                                : _c(
                                    "a",
                                    {
                                      staticClass: "filters__clear",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.clearFilters("niche")
                                        },
                                      },
                                    },
                                    [_vm._v("Clear")]
                                  ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "filters__apply",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.applyFilters()
                                      _vm.activeFilter = ""
                                    },
                                  },
                                },
                                [_vm._v("Apply")]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "filters__item",
                    class: {
                      "filters__item--dd":
                        _vm.breakPoint == "desktop" ||
                        _vm.breakPoint == "desktop-l",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "filters__select",
                        class: {
                          active: _vm.activeFilter == "processes",
                          selected: _vm.processesFilters.counter > 0,
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.setActiveFilter("processes")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "filters__label",
                            class: { hidden: _vm.processesFilters.counter },
                          },
                          [_vm._v(" Process ")]
                        ),
                        _vm._v(" "),
                        _vm.processesFilters.counter
                          ? _c("span", { staticClass: "filters__selected" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.processesFilters.label) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.processesFilters.counter > 1
                          ? _c("span", { staticClass: "filters__counter" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.processesFilters.counter) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "filters__box",
                        class: { active: _vm.activeFilter == "processes" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "ul",
                          {
                            ref: "processesList",
                            staticClass: "filters__box-list",
                          },
                          _vm._l(_vm.filters.processes, function (item, index) {
                            return _c(
                              "li",
                              { key: index, staticClass: "filters__box-item" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "filters__option" },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: item.checked,
                                          expression: "item.checked",
                                        },
                                      ],
                                      staticClass: "filters__option-checkbox",
                                      attrs: { type: "checkbox" },
                                      domProps: {
                                        checked: Array.isArray(item.checked)
                                          ? _vm._i(item.checked, null) > -1
                                          : item.checked,
                                      },
                                      on: {
                                        change: function ($event) {
                                          var $$a = item.checked,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  item,
                                                  "checked",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  item,
                                                  "checked",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(item, "checked", $$c)
                                          }
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "span",
                                      { staticClass: "filters__option-name" },
                                      [_vm._v(_vm._s(item.title))]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.breakPoint != "mobile" && _vm.breakPoint != "tablet"
                          ? _c("div", { staticClass: "filters__controls" }, [
                              _vm.processesFiltersChanged ||
                              !_vm.processesFilters.query
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "filters__cancel",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.cancelFilters()
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  )
                                : _c(
                                    "a",
                                    {
                                      staticClass: "filters__clear",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.clearFilters("processes")
                                        },
                                      },
                                    },
                                    [_vm._v("Clear")]
                                  ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "filters__apply",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.applyFilters()
                                      _vm.activeFilter = ""
                                    },
                                  },
                                },
                                [_vm._v("Apply")]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "filters__item",
                    class: {
                      "filters__item--dd":
                        _vm.breakPoint == "desktop" ||
                        _vm.breakPoint == "desktop-l",
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "filters__select",
                        class: {
                          active: _vm.activeFilter == "capabilities",
                          selected: _vm.capabilitiesFilters.counter > 0,
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.setActiveFilter("capabilities")
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "filters__label",
                            class: { hidden: _vm.capabilitiesFilters.counter },
                          },
                          [_vm._v(" Capabilities ")]
                        ),
                        _vm._v(" "),
                        _vm.capabilitiesFilters.counter
                          ? _c("span", { staticClass: "filters__selected" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.capabilitiesFilters.label) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.capabilitiesFilters.counter > 1
                          ? _c("span", { staticClass: "filters__counter" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.capabilitiesFilters.counter) +
                                  "\n            "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "filters__box",
                        class: { active: _vm.activeFilter == "capabilities" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            ref: "capabilitiesList",
                            staticClass: "filters__box-list",
                          },
                          _vm._l(_vm.filters.capabilities, function (item, i) {
                            return i == 0 ||
                              item.category !=
                                _vm.filters.capabilities[i - 1].category
                              ? _c(
                                  "div",
                                  {
                                    key: i,
                                    staticClass: "filters__box-list-group",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "filters__box-list-label",
                                      },
                                      [_vm._v(_vm._s(item.category))]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.filters.capabilities.filter(function (
                                        i
                                      ) {
                                        return i.category == item.category
                                      }),
                                      function (item, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "filters__box-item",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass:
                                                  "filters__option filters__option",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: item.checked,
                                                      expression:
                                                        "item.checked",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "filters__option-checkbox",
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      item.checked
                                                    )
                                                      ? _vm._i(
                                                          item.checked,
                                                          null
                                                        ) > -1
                                                      : item.checked,
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      var $$a = item.checked,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              item,
                                                              "checked",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              item,
                                                              "checked",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          item,
                                                          "checked",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "filters__option-name",
                                                  },
                                                  [_vm._v(_vm._s(item.title))]
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                  ],
                                  2
                                )
                              : _vm._e()
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _vm.breakPoint != "mobile" && _vm.breakPoint != "tablet"
                          ? _c("div", { staticClass: "filters__controls" }, [
                              _vm.capabilitiesFiltersChanged ||
                              !_vm.capabilitiesFilters.query
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "filters__cancel",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.cancelFilters()
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  )
                                : _c(
                                    "a",
                                    {
                                      staticClass: "filters__clear",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.clearFilters(
                                            "capabilities"
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Clear")]
                                  ),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass: "filters__apply",
                                  attrs: { href: "#" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      _vm.applyFilters()
                                      _vm.activeFilter = ""
                                    },
                                  },
                                },
                                [_vm._v("Apply")]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    ref: _vm.breakPoint == "desktop" ? "moreFiltersList" : "",
                    staticClass: "filters__group",
                    class: {
                      "filters__group--visible": _vm.breakPoint == "desktop-l",
                      "filters__group--container": _vm.breakPoint == "desktop",
                      active:
                        _vm.activeFilter == "moreFilters" &&
                        _vm.breakPoint == "desktop",
                    },
                    on: {
                      click: function (e) {
                        if (
                          _vm.activeFilter == "moreFilters" &&
                          _vm.breakPoint == "desktop"
                        ) {
                          e.stopPropagation()
                        }
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "filters__group-items",
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "filters__item",
                            class: {
                              "filters__item--dd":
                                _vm.breakPoint == "desktop-l",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "filters__select",
                                class: {
                                  active: _vm.activeFilter == "lastingMethods",
                                  selected:
                                    _vm.lastingMethodsFilters.counter > 0,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.setActiveFilter("lastingMethods")
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "filters__label",
                                    class: {
                                      hidden: _vm.lastingMethodsFilters.counter,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Lasting Method\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.lastingMethodsFilters.counter
                                  ? _c(
                                      "span",
                                      { staticClass: "filters__selected" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.lastingMethodsFilters.label
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.lastingMethodsFilters.counter > 1
                                  ? _c(
                                      "span",
                                      { staticClass: "filters__counter" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.lastingMethodsFilters.counter
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "filters__box",
                                class: {
                                  active: _vm.activeFilter == "lastingMethods",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              },
                              [
                                _c(
                                  "ul",
                                  {
                                    ref: "lastingMethodsList",
                                    staticClass: "filters__box-list",
                                  },
                                  _vm._l(
                                    _vm.filters.lastingMethods,
                                    function (item, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass: "filters__box-item",
                                        },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "filters__option" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.checked,
                                                    expression: "item.checked",
                                                  },
                                                ],
                                                staticClass:
                                                  "filters__option-checkbox",
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    item.checked
                                                  )
                                                    ? _vm._i(
                                                        item.checked,
                                                        null
                                                      ) > -1
                                                    : item.checked,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a = item.checked,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            item,
                                                            "checked",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            item,
                                                            "checked",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        item,
                                                        "checked",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "filters__option-name",
                                                },
                                                [_vm._v(_vm._s(item.title))]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _vm.breakPoint != "mobile" &&
                                _vm.breakPoint != "tablet" &&
                                _vm.breakPoint != "desktop"
                                  ? _c(
                                      "div",
                                      { staticClass: "filters__controls" },
                                      [
                                        _vm.lastingMethodsFiltersChanged ||
                                        !_vm.lastingMethodsFilters.query
                                          ? _c(
                                              "a",
                                              {
                                                staticClass: "filters__cancel",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.cancelFilters()
                                                  },
                                                },
                                              },
                                              [_vm._v("Cancel")]
                                            )
                                          : _c(
                                              "a",
                                              {
                                                staticClass: "filters__clear",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.clearFilters(
                                                      "lastingMethods"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Clear")]
                                            ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "filters__apply",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                _vm.applyFilters()
                                                _vm.activeFilter = ""
                                              },
                                            },
                                          },
                                          [_vm._v("Apply")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "filters__item",
                            class: {
                              "filters__item--dd":
                                _vm.breakPoint == "desktop-l",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "filters__select",
                                class: {
                                  active: _vm.activeFilter == "dailyProduction",
                                  selected:
                                    _vm.dailyProductionFilters.counter > 0,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.setActiveFilter(
                                      "dailyProduction"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "filters__label",
                                    class: {
                                      hidden:
                                        _vm.dailyProductionFilters.counter,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                  Daily Production (Pairs)\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.dailyProductionFilters.counter
                                  ? _c(
                                      "span",
                                      { staticClass: "filters__selected" },
                                      [
                                        _vm._v(
                                          "\n                  Daily Production (Pairs)\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.dailyProductionFilters.counter
                                  ? _c(
                                      "span",
                                      { staticClass: "filters__counter" },
                                      [_vm._v(" 1 ")]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "filters__box",
                                class: {
                                  active: _vm.activeFilter == "dailyProduction",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "filters__box-label" },
                                  [
                                    _vm._v(
                                      "\n                  " +
                                        _vm._s(_vm.dailyProductionFrom || 1) +
                                        " - " +
                                        _vm._s(_vm.dailyProductionTo || 2000) +
                                        "\n                "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("vue-slider", {
                                  ref: "dailyProductionSlider",
                                  staticClass: "supplier__range-slider",
                                  attrs: {
                                    value: [
                                      +_vm.dailyProductionFrom || 1,
                                      +_vm.dailyProductionTo || 2000,
                                    ],
                                    data: [
                                      1, 20, 50, 100, 150, 200, 250, 300, 350,
                                      400, 450, 500, 550, 600, 650, 700, 800,
                                      900, 1000, 1100, 1200, 1500, 2000,
                                    ],
                                    min: 1,
                                    max: 2000,
                                    width: "100%",
                                    tooltip: false,
                                    bgStyle: {
                                      backgroundColor: "rgba(55, 73, 94, 0.13)",
                                    },
                                    sliderStyle: {
                                      backgroundColor: "#fff",
                                      border: "2px solid #37495e",
                                    },
                                    processStyle: {
                                      backgroundColor: "rgba(55, 73, 94, 0.8)",
                                    },
                                  },
                                  on: { callback: _vm.dailyProductionChange },
                                }),
                                _vm._v(" "),
                                _vm.breakPoint != "mobile" &&
                                _vm.breakPoint != "tablet" &&
                                _vm.breakPoint != "desktop"
                                  ? _c(
                                      "div",
                                      { staticClass: "filters__controls" },
                                      [
                                        _vm.dailyProductionFiltersChanged ||
                                        !_vm.dailyProductionFilters.query
                                          ? _c(
                                              "a",
                                              {
                                                staticClass: "filters__cancel",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.cancelFilters()
                                                  },
                                                },
                                              },
                                              [_vm._v("Cancel")]
                                            )
                                          : _c(
                                              "a",
                                              {
                                                staticClass: "filters__clear",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.clearFilters(
                                                      "dailyProduction"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Clear")]
                                            ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "filters__apply",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                _vm.applyFilters()
                                                _vm.activeFilter = ""
                                              },
                                            },
                                          },
                                          [_vm._v("Apply")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "filters__item",
                            class: {
                              "filters__item--dd":
                                _vm.breakPoint == "desktop-l",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "filters__select",
                                class: {
                                  active: _vm.activeFilter == "sizeRange",
                                  selected: _vm.sizeRangeFilter.counter > 0,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.setActiveFilter("sizeRange")
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "filters__label",
                                    class: {
                                      hidden: _vm.sizeRangeFilter.counter,
                                    },
                                  },
                                  [_vm._v(" Size Range ")]
                                ),
                                _vm._v(" "),
                                _vm.sizeRangeFilter.counter
                                  ? _c(
                                      "span",
                                      { staticClass: "filters__selected" },
                                      [_vm._v(" Size Range ")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.sizeRangeFilter.counter
                                  ? _c(
                                      "span",
                                      { staticClass: "filters__counter" },
                                      [_vm._v(" 1 ")]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "filters__box",
                                class: {
                                  active: _vm.activeFilter == "sizeRange",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "filters__box-label" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.sizeRangeFrom || 11) +
                                        " - " +
                                        _vm._s(_vm.sizeRangeTo || 55) +
                                        " "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("vue-slider", {
                                  ref: "sizeRangeSlider",
                                  staticClass: "supplier__range-slider",
                                  attrs: {
                                    value: [
                                      _vm.sizeRangeFrom || 11,
                                      _vm.sizeRangeTo || 55,
                                    ],
                                    min: 11,
                                    max: 55,
                                    width: "100%",
                                    tooltip: false,
                                    bgStyle: {
                                      backgroundColor: "rgba(55, 73, 94, 0.13)",
                                    },
                                    sliderStyle: {
                                      backgroundColor: "#fff",
                                      border: "2px solid #37495e",
                                    },
                                    processStyle: {
                                      backgroundColor: "rgba(55, 73, 94, 0.8)",
                                    },
                                  },
                                  on: { callback: _vm.sizeRangeChange },
                                }),
                                _vm._v(" "),
                                _vm.breakPoint != "mobile" &&
                                _vm.breakPoint != "tablet" &&
                                _vm.breakPoint != "desktop"
                                  ? _c(
                                      "div",
                                      { staticClass: "filters__controls" },
                                      [
                                        _vm.sizeRangeFilterChanged ||
                                        !_vm.sizeRangeFilter.query
                                          ? _c(
                                              "a",
                                              {
                                                staticClass: "filters__cancel",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.cancelFilters()
                                                  },
                                                },
                                              },
                                              [_vm._v("Cancel")]
                                            )
                                          : _c(
                                              "a",
                                              {
                                                staticClass: "filters__clear",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.clearFilters(
                                                      "sizeRange"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Clear")]
                                            ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "filters__apply",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                _vm.applyFilters()
                                                _vm.activeFilter = ""
                                              },
                                            },
                                          },
                                          [_vm._v("Apply")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "filters__item",
                            class: {
                              "filters__item--dd":
                                _vm.breakPoint == "desktop-l",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "filters__select",
                                class: {
                                  active: _vm.activeFilter == "associations",
                                  selected: _vm.associationsFilters.counter > 0,
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.setActiveFilter("associations")
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "filters__label",
                                    class: {
                                      hidden: _vm.associationsFilters.counter,
                                    },
                                  },
                                  [_vm._v(" Associations ")]
                                ),
                                _vm._v(" "),
                                _vm.associationsFilters.counter
                                  ? _c(
                                      "span",
                                      { staticClass: "filters__selected" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.associationsFilters.label
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.associationsFilters.counter > 1
                                  ? _c(
                                      "span",
                                      { staticClass: "filters__counter" },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.associationsFilters.counter
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "filters__box",
                                class: {
                                  active: _vm.activeFilter == "associations",
                                },
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              },
                              [
                                _c(
                                  "ul",
                                  {
                                    ref: "associationsList",
                                    staticClass: "filters__box-list",
                                  },
                                  _vm._l(
                                    _vm.filters.associations,
                                    function (item) {
                                      return _c(
                                        "li",
                                        {
                                          key: item.title,
                                          staticClass: "filters__box-item",
                                        },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "filters__option" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: item.checked,
                                                    expression: "item.checked",
                                                  },
                                                ],
                                                staticClass:
                                                  "filters__option-checkbox",
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    item.checked
                                                  )
                                                    ? _vm._i(
                                                        item.checked,
                                                        null
                                                      ) > -1
                                                    : item.checked,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    var $$a = item.checked,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            item,
                                                            "checked",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            item,
                                                            "checked",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        item,
                                                        "checked",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "filters__option-name",
                                                },
                                                [_vm._v(_vm._s(item.title))]
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                                _vm._v(" "),
                                _vm.breakPoint != "mobile" &&
                                _vm.breakPoint != "tablet" &&
                                _vm.breakPoint != "desktop"
                                  ? _c(
                                      "div",
                                      { staticClass: "filters__controls" },
                                      [
                                        _vm.associationsFiltersChanged ||
                                        !_vm.associationsFilters.query
                                          ? _c(
                                              "a",
                                              {
                                                staticClass: "filters__cancel",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.cancelFilters()
                                                  },
                                                },
                                              },
                                              [_vm._v("Cancel")]
                                            )
                                          : _c(
                                              "a",
                                              {
                                                staticClass: "filters__clear",
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function ($event) {
                                                    $event.preventDefault()
                                                    return _vm.clearFilters(
                                                      "associations"
                                                    )
                                                  },
                                                },
                                              },
                                              [_vm._v("Clear")]
                                            ),
                                        _vm._v(" "),
                                        _c(
                                          "a",
                                          {
                                            staticClass: "filters__apply",
                                            attrs: { href: "#" },
                                            on: {
                                              click: function ($event) {
                                                $event.preventDefault()
                                                _vm.applyFilters()
                                                _vm.activeFilter = ""
                                              },
                                            },
                                          },
                                          [_vm._v("Apply")]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.breakPoint == "tablet" ||
                        _vm.breakPoint == "desktop"
                          ? _c(
                              "div",
                              { staticClass: "filters__group-footer" },
                              [
                                _vm.filtersChanged || !_vm.appliedFilters
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "filters__cancel",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            _vm.cancelFilters()
                                            _vm.activeFilter = ""
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                Cancel\n              "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "a",
                                      {
                                        staticClass: "filters__cancel",
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            return _vm.clearMoreFilters()
                                          },
                                        },
                                      },
                                      [_vm._v(" Clear ")]
                                    ),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass: "filters__apply",
                                    attrs: { href: "#" },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        _vm.applyFilters()
                                        _vm.activeFilter = ""
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                Apply\n              "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.breakPoint != "mobile" && _vm.breakPoint != "desktop-l"
          ? _c(
              "div",
              {
                staticClass: "filters__item filters__item--dd",
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.setActiveFilter("moreFilters")
                  },
                },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "filters__select",
                    class: {
                      active: _vm.activeFilter == "moreFilters",
                      selected: _vm.moreFiltersCounter > 0,
                    },
                  },
                  [
                    _c("span", { staticClass: "filters__label" }, [
                      _vm._v(" More Filters "),
                    ]),
                    _vm._v(" "),
                    _vm.moreFiltersCounter
                      ? _c("span", { staticClass: "filters__counter" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.moreFiltersCounter) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.breakPoint == "mobile"
          ? _c("div", { staticClass: "filters__mobile-controls" }, [
              _vm.filtersChanged || !_vm.appliedFilters
                ? _c(
                    "a",
                    {
                      staticClass: "filters__cancel",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.cancelFilters()
                          _vm.$emit("update:activeFilters", false)
                        },
                      },
                    },
                    [_vm._v("\n        Cancel\n      ")]
                  )
                : _c(
                    "a",
                    {
                      staticClass: "filters__cancel",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.$router.push({
                            name: _vm.$route.name,
                            query: null,
                          })
                          _vm.$emit("update:activeFilters", false)
                        },
                      },
                    },
                    [_vm._v("\n        Clear\n      ")]
                  ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "filters__apply",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.applyFilters()
                      _vm.$emit("update:activeFilters", false)
                    },
                  },
                },
                [_vm._v("\n        Apply\n      ")]
              ),
            ])
          : _vm._e(),
      ]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "filters__toggle",
        class: { active: _vm.activeFilters, selected: _vm.countAllFilters },
        on: {
          click: function ($event) {
            _vm.applyFilters()
            _vm.$emit("update:activeFilters", !_vm.activeFilters)
          },
        },
      },
      [
        _c("span", { staticClass: "filters__toggle-label" }, [
          _vm._v("Filters"),
        ]),
        _vm._v(" "),
        _vm.countAllFilters
          ? _c("span", { staticClass: "filters__counter" }, [
              _vm._v("\n      " + _vm._s(_vm.countAllFilters) + "\n    "),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }